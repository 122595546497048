<section class="offers">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-medium" >
          <h2 class="heading-secondary"> {{"offers.title" | translate}}</h2>
          <p>{{"offers.Dontmissourspecialoffers" | translate}}</p>
      </div>
      </div>
       <div class="col-12 col-md-6" *ngFor="let promo of promos; let i = index">
        <div class="offers__block">
          <div class="card" >
            <img class="card-img-top"
            src="{{ promo.URL | replace : 'http://169.239.39.103:83' : 'https://dev.souhoola.net' }} "
             alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">  {{promo.Title}}
                <span class="badge bg-success">{{"offers.New" | translate}}</span>
              </h5>
              <p class="card-text">{{promo.Description}}</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item list-group-item--purpple"
              [routerLink]="['/offers' | localize, promo.Id]">
              {{"offers.Readmore" | translate}}
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

