import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Pagination, Swiper } from "swiper/core";
import swiper from 'swiper/core';
import AOS from 'aos';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
   @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      this.document.documentElement.classList.remove("no-scroll");

      AOS.init();
    }


  }
  scrollSmooth(){
    window.scrollTo({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    }

}
