
<br />
<br />
<section class="section-about-us-1"
data-aos="fade-up"
     data-aos-duration="2000">
  <div class="container">
    <div class="mb-medium" >
      <h2 class="heading-secondary">
        {{"aboutus.ABOUTSOUHOOLA" | translate}} 
      </h2>
  </div>
    <div  class="row">
      <div class="col-12 col-lg-4">
        <div class="text-center img-box" >
          <img class="img-box-section" src="assets/img/aboutus/aboutus1-min.png" alt="">
        </div>
      </div>
      <div class="col-12 col-lg-8">
          <p class="title-box lineHeightArbic">
            {{"aboutus.aboutsouhoolaDetails" | translate}}

          </p>
      </div>
    </div>
  </div>
</section>
<section class="section-about-us-2" id="ourvision" data-aos="fade-up" data-aos-duration="2000">
  <div class="container" >
    <div class="mb-medium">
      <h2 class="heading-secondary">
        {{"aboutus.OURVISION&MISSION" | translate}} 
      </h2>
  </div>
    <div class="row">
      <div class="col-12 col-lg-8 order-2 order-md-1">
        <span class="about-us-headpoint">
          {{"aboutus.Ouraspirationisto:" | translate}}
        </span>
        <ul class="aspiration lineHeightArbic">
          <li>
            {{"aboutus.Ouraspirationisto:1" | translate}}
          </li>
          <li>
            {{"aboutus.Ouraspirationisto:2" | translate}}
          </li>
          <li>
            {{"aboutus.Ouraspirationisto:3" | translate}}
          </li>
        </ul>
        <p class="title-box lineHeightArbic">
          {{"aboutus.Ouraspirationisto:4" | translate}}
        </p>
        </div>
      <div class="col-12 col-lg-4 order-1 order-md-2">
        <div class="text-center img-box" >
          <img class="img-box-section img-box-section-middle" src="assets/img/aboutus/aboutus2-min.png" alt="">
        </div>
      </div>

    </div>
  </div>
</section>

<section class="section-about-us-3" data-aos="fade-up" data-aos-duration="2000" >
  <div class="container">
    <div class="mb-medium">
      <h2 class="heading-secondary">
        {{"aboutus.OURBUSINESSMODEL" | translate}}
      </h2>
  </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="text-center img-box" >
          <img class="img-box-section" src="assets/img/aboutus/aboutus3-min.png" alt="">
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <p class="title-box lineHeightArbic">
          {{"aboutus.OURBUSINESSMODEL1" | translate}}

        </p>
        <span class="about-us-headpoint">
          {{"aboutus.OURBUSINESSMODEL2" | translate}}
        </span>
        <ul class="aspiration lineHeightArbic">
          <li>
            {{"aboutus.OURBUSINESSMODEL3" | translate}}
          </li>
          <li>
            {{"aboutus.OURBUSINESSMODEL4" | translate}}
          </li>
          <li>
            {{"aboutus.OURBUSINESSMODEL5" | translate}}
          </li>
          <li>
            {{"aboutus.OURBUSINESSMODEL6" | translate}}

          </li>
        </ul>

        </div>

    </div>
  </div>
</section>

<!-- Top Management End-->

<app-our-partners></app-our-partners>
