    <div class="section-adv">
      <div class="container">
        <div class="row">
        <!-- <app-slider-our-network></app-slider-our-network> -->
        </div>
      </div>
  </div>
  <div class="merchants">
    <div class="container">
      <div class="row">
        <div class="col-xs-3">
          <div class="list-group" >
            <a  class="list-group-item list-group-item-action"
            [class.active]="!category"
            [routerLink]="'/ournetwork' | localize"
            (click)="getAll()">{{"AllCategory" | translate}}</a>
            <a *ngFor="let categoryItem of categories"
            [routerLink]="'/ournetwork' | localize"
            [queryParams]="{category: categoryItem.name}"
            [class.active]="category === categoryItem.name"
            (click)="getfilterCategory(categoryItem.code)"
             class="list-group-item list-group-item-action"> {{categoryItem.name | translate}}
            </a>
          </div>
        </div>
        <div class="col-md-9 col-xs-12 m-l">
          <div class="row">
            <div class="col-md-12">
              <div class="flex-search">
                <div class="main-search">
                  <!-- Another variation with a button -->
                  <form (ngSubmit)="onSubmit()">
                  <div class="input-group">
                    <input class="form-control" type="text"   [(ngModel)]="merchantName"
                    placeholder="{{'Search'| translate}}">
                    <div class="input-group-append" >
                        <span class="input-group-text">
                          <button type="submit" class="buttonSearch">
                            <i class="fa fa-search"></i>
                          </button>
                        </span>
                    </div>
                </div>
              </form>
                </div>
                <div class="btn-toolbar">
                  <div class="button" (click)="openAddFileDialog()">
                    <img src="assets/img/ournetworks/Icon awesome-filter.svg" alt="iconSouhoola" loading="lazy">
                  </div>
                </div>
              </div>
            </div>
              <ng-container *ngFor="let item of filterMerchants">
               <div class="col-lg-3 col-md-4 col-xs-6 card-items">
                 <a [routerLink]="item.merchantName" [state]="item">
                  <div class="card">
                    <div *ngIf="item.merchantLogo else loading">
                     <img  class="card-img-top" loading="lazy"
                     [src]="item.merchantLogo | replace : 'http://169.239.39.129:8890' : 'https://images.souhoola.org'"
                      onerror="this.src='https://b.top4top.io/p_1878ildjb1.png'">
                    </div>
                    <ng-template #loading>
                     <img  class="card-img-top" loading="lazy"
                     src="assets/img/ournetworks/placeHolder.png">
                    </ng-template>
                    <div class="card-body">
                      <span class="card-text" >{{item.merchantName}}</span>
                    </div>
                  </div>
                 </a>
               </div>
               </ng-container>
               <hr>
               <ngb-pagination [collectionSize]="320" [pageSize]="24"
                [(page)]="page" [maxSize]="7" [rotate]="true" (pageChange)="loadPage($event)">
              </ngb-pagination>
            </div>
        </div>
      </div>
    </div>
   </div>