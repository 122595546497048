import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userType = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
  }

  loginCorp(data: any): Observable<any> {
    return this.http.post(`${environment.apiLoginCorp}`, data);
  }

  loginMerchant(data: any): Observable<any> {
    return this.http.post(`${environment.apiLogin}`, data);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  userLogout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('CorpCode');
    localStorage.removeItem('CorpName');
    localStorage.removeItem('SegmentsList');
  }


  register(data: any): Observable<any> {
    return this.http.post(`${environment.apiRegister}`, data);
  }

  getClientToken(data: any): Observable<any> {
    return this.http.post(`${environment.getClientToken}`, data);
  }
}


