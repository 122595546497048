import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import AOS from 'aos';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
   @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      this.document.documentElement.classList.remove("no-scroll");

      AOS.init();
    }

  }
  scrollSmooth(){
    window.scrollTo({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    }
}
