import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted:boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document,
  @Inject(PLATFORM_ID) private platformId: Object, private fb:FormBuilder) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      this.prepareForm();
      this.document.documentElement.classList.remove("no-scroll");
    }

  }
  prepareForm() {
    this.contactUsForm = this.fb.group({
      Name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      MobileNum: ['', [Validators.required, Validators.minLength(11), Validators.pattern("^01[0-2,5]{1}[0-9]{8}$")]],
      Enquiry: ['', [Validators.required]],
      Attach: [''],
      Message: ['', [Validators.required]],



  });

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  get f() {
    return this.contactUsForm.controls;
  }
  onSubmit() {
    this.submitted = true;
  }
  scrollSmooth(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    }
}
