import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FAQsComponent } from './components/faqs/faqs.component';
import { SharedModule } from '../../shared/shared.module';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CareersComponent } from './components/careers/careers.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { NewsComponent } from './components/news/news.component';
import { OurPartnersComponent } from './components/our-partners/our-partners.component';
import { OurProductComponent } from './components/our-product/our-product.component';
import { TopManagementComponent } from './components/top-management/top-management.component';
import { NewOffersComponent } from './components/new-offers/new-offers.component';


@NgModule({
  declarations: [
    FAQsComponent,
    CareersComponent,
    NewsComponent,
    OurProductComponent,
    AboutUsComponent,
    OurPartnersComponent,
    TopManagementComponent,
    ContactUsComponent,
    NewOffersComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class StaticModule {
}
