import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth/auth.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,private localize: LocalizeRouterService,public toaster: ToastrService,
    private authService: AuthService) {}
    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
      return next.handle(request).pipe(catchError(err => {
        const error = (err.error ? err.error.message : null) || err.statusText;
        switch (err.status) {
          case 401: {
            // token expired -> goto login, dont return error
            this.toaster.error('برجاء تسجيل الدخول ');
            this.authService.userLogout();
            let translatedPath: any = this.localize.translateRoute('/home');
            this.router.navigate([translatedPath]);
            return of(error);      // <-- return observable using `of`
            break;
          }
          
    
          case 500: {
            return throwError(error);
            break;
          }
          case 400: {
            this.toaster.error('An error has occurred.');
            return throwError(error);
           break;
          }
    
          default: {
            if (error != "OK") {
              return throwError(error);
            }
            return of(error);      // <-- return observable using `of`
            break;
          }
        }
      }));
    }

  }

