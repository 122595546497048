import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {

  offersDetails:any;
  promos:any;
  offerId: any;
  offers:any;
  constructor(private route: ActivatedRoute, private offerDetails: OurNetworkService,
    private translate: TranslateService) { 
    }
  ngOnInit(): void {
  }

}
