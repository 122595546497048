<section class="section-about-us"
data-aos="fade-up"
data-aos-duration="2000" >
  <div class="container">
    <div class=" mainColor" style="font-size: 30px;">
    <span class="mainColor">
    {{"home.whySouhoola" | translate}}</span>
    </div>
    <div  class="row" >
      <div class="col-12 col-md-6 col-lg-3">
        <div class="cards-features ">
          <div >
            <img  style="width: 150px;" src="/assets/img/maps-and-flags.png" alt="">
          </div>
          <h2 class="title-box">
            <span> {{"home.getan" | translate}}</span> <span class="textcolor"> {{"home.Instantapproval" | translate}}</span>
            <br />
            <span class="textcolor"> {{"home.approval" | translate}}</span>
          </h2>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3" >
        <div class="cards-features"   >
          <div >
            <img  style="width: 150px;" src="/assets/img/appointment.png"  alt="">
          </div>
          <h2 class="title-box">
            {{'home.Upto60Months'| translate}} <span class="textcolor">{{'home.60Months'| translate}}</span>
            <br />{{'home.Installment'| translate}}
          </h2>
        </div>

      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="cards-features">
          <div>
            <img  style="width: 150px;" src="/assets/img/outlet.png"  alt="outlet">

          </div>
            <h2 class="title-box">
              <span class="textcolor"> {{'home.1000+' | translate}}</span> {{'home.1000+Outlets' | translate}} <br>{{'home.AllOverEgypt'| translate}}
              </h2>
        </div>

      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="cards-features">
          <div>
            <img  style="width: 150px;" src="/assets/img/money.png"  alt="money">
          </div>
          <h2 class="title-box">
            {{'home.Upto2MillionEGP' | translate}}<span class="textcolor"> {{'home.250' | translate}}</span><br />
            <span class="textcolor"> {{'home.Thousand' | translate}}</span>
          </h2>
        </div>

      </div>
    </div>
  </div>
</section>
