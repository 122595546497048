<div class="faqs-section"
     data-aos="fade-up"
     data-aos-duration="2000">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="mb-medium">
          <h2 class="heading-secondary">
            {{"faqs.FrequentlyAsksQuestions" | translate}}
          </h2>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="{{'faqs.General' | translate}}">
            <div id="accordion">
              <div class="card">
                <ngb-accordion [closeOthers]="true">
                  <ngb-panel id="toggle-1" [title]='"faqs.General1" | translate' class="card__item">
                    <ng-template ngbPanelContent>
                      {{"faqs.General2" | translate}}
                    </ng-template>
                  </ngb-panel>

                  <ngb-panel id="toggle-2" [title]='"faqs.General3" | translate' class="card__item">
                    <ng-template ngbPanelContent>
                      {{"faqs.General4" | translate}}
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="toggle-3" [title]='"faqs.General5" | translate' class="card__item">
                    <ng-template ngbPanelContent>
                      {{"faqs.General6" | translate}}
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="toggle-4" [title]='"faqs.General7" | translate' class="card__item">
                    <ng-template ngbPanelContent>
                      {{"faqs.General8" | translate}}
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="toggle-5" [title]='"faqs.General9" | translate' class="card__item">
                    <ng-template ngbPanelContent>
                      {{"faqs.General10" | translate}}
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>

            </div>
          </mat-tab>
          <!--<mat-tab label="{{'faqs.Merchant' | translate}}">
            <div id="accordion">
              <ngb-accordion [closeOthers]="true">
                <ngb-panel id="toggle-1" [title]='"faqs.General15" | translate'>
                  <ng-template ngbPanelContent>
                    1) {{"faqs.General16-1" | translate}}
                    <br>
                    2) {{"faqs.General16-2" | translate}}
                    <br>
                    3) {{"faqs.General16-3" | translate}}
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="toggle-2" [title]='"faqs.General11" | translate'>
                  <ng-template ngbPanelContent>
                    {{"faqs.General12" | translate}}
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="toggle-3" [title]='"faqs.General13" | translate'>
                  <ng-template ngbPanelContent>
                    {{"faqs.General14" | translate}}
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="toggle-4" [title]='"faqs.General17" | translate'>
                  <ng-template ngbPanelContent>
                    {{"faqs.General18" | translate}}
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </mat-tab>-->
        </mat-tab-group>

      </div>
      <div class="col-12 col-lg-6 card-info">
        <div class="card mb-3 card-m">
          <img class="card-img-top" src="assets/img/faqs/FAQs.png" alt="Souhoola FAQS">
          <div class="card-body">
            <p class="card-text">
              {{"faqs.cardTextFAQS" | translate}}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


