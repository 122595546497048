import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ehabcenter',
  templateUrl: './ehabcenter.component.html',
  styleUrls: ['./ehabcenter.component.scss']
})
export class EhabcenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
