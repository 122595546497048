<div class="list-buttons-container">
  <a *ngIf="!isLoggedIn()" class="list-buttons list-buttons-merchant" (click)="openMerchant()">
    <img src="assets/img/Icon metro-shop - Copy.svg" alt="Souhoola">
    <span>{{'merchant' | translate}}</span>
  </a>
  <br />
<!--<a id="corporatelist" *ngIf="!isLoggedIn()" class="list-buttons list-buttons-corporate" (click)="openCorporate()">
    <i class="fa fa-users" aria-hidden="true"></i>
    <span class="iconAdmin">{{"corporate" | translate}}</span>
  </a>-->

  <a id="Transaction" (click)="scanQrCode()" *ngIf="isLoggedIn()" class="list-buttons list-buttons-new-user">
    <img src="assets/img/group-min.png" alt="Transaction">
    <span class="iconAdmin">{{"Transaction" | translate}}</span>
  </a>

  <a id="pendingList" *ngIf="isLoggedIn()" class="list-buttons list-buttons-pending" [routerLink]="'/merchantadmin/PendingTransaction' | localize">
    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
    <span class="iconAdmin">{{"Pending" | translate}}</span>
  </a>

  <a id="BranchState" *ngIf="isLoggedIn()" class="list-buttons list-buttons-dashboard" [routerLink]="'/merchantadmin/BranchStatement' | localize">
    <img src="/assets/img/dashboard.png" alt="BranchState">
    <span class="iconAdmin">{{"BranchState" | translate}}</span>
  </a>
</div>

