import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MerchantQrcodeComponent } from 'src/app/modules/merchant-admin/merchant-qrcode/merchant-qrcode.component';
import { LoginComponent } from 'src/app/core/authentication/components/login/login.component';

@Component({
  selector: 'app-list-buttons',
  templateUrl: './list-buttons.component.html',
  styleUrls: ['./list-buttons.component.scss']
})
export class ListButtonsComponent implements OnInit {
  dialogRef: any;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

  scanQrCode(): void {
    this.dialogRef = this.dialog.open(MerchantQrcodeComponent, { panelClass: 'my-custom-qrcode-class' });
  }

  isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  openMerchant(): void {
    this.auth.userType.next(true);
    this.openDialog();
  }

  openCorporate(): void {
    this.auth.userType.next(false);
    this.openDialog();
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(LoginComponent, { panelClass: 'my-custom-login-class' });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.router.navigate(['../'], { relativeTo: this.route }).then();
    });
  }
}
