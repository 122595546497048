import { Component, OnInit } from '@angular/core';
import Swiper, { Autoplay} from 'swiper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import AOS from 'aos';

@Component({
  selector: 'app-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.scss']
})
export class OurPartnersComponent implements OnInit {
  currentLang:any;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  Swiper.use([Autoplay]);
  this.translate.onLangChange.subscribe((res: LangChangeEvent) => {
    setTimeout(() => {
      this.currentLang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    }, 50);
  })

  AOS.init();
  }

    stopSwip(event) {
      event.target.swiper.autoplay.stop();
  }
    startSwip(event) {
      event.target.swiper.autoplay.start();
  }
}
