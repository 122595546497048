<h4 mat-dialog-title>{{"Chooseacity" | translate}}</h4>
<input type="text" #query (keyup)="filter(query.value)" class="form-control"
 placeholder="{{'Search' | translate}}">
<div mat-dialog-content>
    <a
    *ngFor="let city of filterCity"
    (click)="getMerchantByCity(city.cityCode)"
     class="list-group-item list-group-item-action">
     {{city.cityName}}
    </a>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{"Back" | translate}}</button>
</div>
