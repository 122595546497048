import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './modules/static/components/about-us/about-us.component';
import { CareersComponent } from './modules/static/components/careers/careers.component';
import { ContactUsComponent } from './modules/static/components/contact-us/contact-us.component';
import { FAQsComponent } from './modules/static/components/faqs/faqs.component';
import { JoinUsComponent } from './core/pages/join-us/join-us.component';
import { NewsComponent } from './modules/static/components/news/news.component';
import { OurProductComponent } from './modules/static/components/our-product/our-product.component';
import { OffersComponent } from './core/pages/offers/offers.component';
import { OfferDetailsComponent } from './core/pages/offer-details/offer-details.component';
import { OurNetworkPageComponent } from './modules/our-networks/components/our-network-page/our-network-page.component';
import { OurNetworkItemsComponent } from './modules/our-networks/components/our-network-items/our-network-items.component';
import { HomescreenComponent } from './core/components/homescreen/homescreen.component';
import { OfferItemComponent } from './core/pages/offer-item/offer-item.component';
import { MorshedyComponent } from './core/pages/morshedy/morshedy.component';
import { Dream2000Component } from './core/pages/dream2000/dream2000.component';
import { MobilatyComponent } from './core/pages/mobilaty/mobilaty.component';
import { XiaomiComponent } from './core/pages/xiaomi/xiaomi.component';
import { TradelineComponent } from './core/pages/tradeline/tradeline.component';
import { XprsComponent } from './core/pages/xprs/xprs.component';
import { MiamicenterComponent } from './core/pages/miamicenter/miamicenter.component';
import { EhabcenterComponent } from './core/pages/ehabcenter/ehabcenter.component';
import { SpinneysComponent } from './core/pages/spinneys/spinneys.component';
import { DubaiphoneComponent } from './core/pages/dubaiphone/dubaiphone.component';
import { RaneenComponent } from './core/pages/raneen/raneen.component';
import { NewOffersComponent } from './modules/static/components/new-offers/new-offers.component';

export const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomescreenComponent
  },
  {
    path: 'career',
    component: CareersComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'faqs',
    component: FAQsComponent
  },
  {
    path: 'offers/raneen',
    component: RaneenComponent
  },
  {
    path: 'contactus',
    component: ContactUsComponent
  },
  {
    path: 'ourproducts',
    component: OurProductComponent
  },
  {
    path: 'aboutus',
    component: AboutUsComponent
  },
  {
    path: 'joinournetwork',
    component: JoinUsComponent
  },
  {
    path: 'offers',
    component: OffersComponent,
  },
  {
    path: 'offers/:id',
    component: OfferItemComponent
  },
  {
    path: 'new-offers',
    component: NewOffersComponent,
  },
  {
    path: 'ournetwork',
    component: OurNetworkPageComponent

  },
  {
    path: 'ournetwork/:id',
    component: OurNetworkItemsComponent

  },
  {
    path: 'merchantadmin',
    loadChildren: () => import('./modules/merchant-admin/merchant-admin.module').then(m => m.MerchantAdminModule)
  },

  {
    path: 'corporates',
    loadChildren: () => import('./modules/corporates/corporates.module').then(m => m.CorporatesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'disabled'

  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
