import { Component, OnInit, ViewChild } from '@angular/core';
import Swiper, { Autoplay} from 'swiper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import AOS from 'aos';

@Component({
  selector: 'app-top-management',
  templateUrl: './top-management.component.html',
  styleUrls: ['./top-management.component.scss']
})
export class TopManagementComponent implements OnInit {
  @ViewChild('newSwiper') newSwiper: any;
  currentLang:any;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((res: LangChangeEvent) => {
      setTimeout(() => {
        this.currentLang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
      }, 50);
    })

    AOS.init();
  }
  ngAfterViewInit(): void {
    console.log(this.newSwiper.swiperRef);

    //Swiper instance will be displayed in console
  }
  stopSwip(event) {
    event.target.swiper.autoplay.stop();
}
  startSwip(event) {
    event.target.swiper.autoplay.start();
}
}
