import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  ListGoodsDashboard = new BehaviorSubject<any>(null);
  ListGoodsPending = new BehaviorSubject<any>(null);
  getMerchantSalesRep = new BehaviorSubject<any>(null);
  getMerchantData = new Subject();
  isHome = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) {
  }


  BranchStatement(data: any) {
    return this.http.post(`${environment.getBranchStatement}`, data);
  }

  setPendingTransaction(data: any) {
    return this.http.post(`${environment.setPendingTransaction}`, data);
  }

  ShowPendingTransaction() {
    return this.http.get(`${environment.showPendingTransaction}`);
  }

  bookloan(data: any) {
    return this.http.post(`${environment.getBookingLoan}`, data);
  }

  refundLoan(data: any) {
    return this.http.post(`${environment.refundLoan}`, data);
  }
}
