<div class="advs" data-aos="fade-up" data-aos-duration="2000">
  <div class="container">
    <div class="mb-medium">
      <h2 class="heading-secondary">
        {{"aboutus.partenr" | translate}}
      </h2>
    </div>
    <div class="row">
      <div class="col-12">
        <swiper [ngClass]="currentLang == 'ar' ? 'ltr' : 'ltr'"
                (mouseenter)="stopSwip($event)" (mouseleave)="startSwip($event)"
                [slidesPerView]="1"
                [spaceBetween]="10"
                [navigation]="false"
                [loop]="false"
                [autoplay]="{delay: 2500, disableOnInteraction: true}"
                [pagination]="false"
                [breakpoints]="{
                  '640': {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  '768': {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  '1024': {
                    slidesPerView: 3,
                    spaceBetween: 20
                  }
                }"
                class="mySwiper">
          <ng-template swiperSlide>
            <img class="img-partner" src="assets/img/aboutus/logo_egabi-02.png" alt="">
          </ng-template>
          <ng-template swiperSlide>
            <img class="img-partner" src="assets/img/aboutus/Raya-2.png" alt="">
          </ng-template>
          <ng-template swiperSlide>
            <img class="img-partner" src="assets/img/aboutus/iscore-info-hub (1).png" alt="">
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>
