import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { first, tap } from 'rxjs/operators';
import { CorporatesService } from 'src/app/core/services/corporates/corporates.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MerchantService } from 'src/app/core/services/merchants/merchant.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userType: boolean;
  loginForm: FormGroup;
  registerForm: FormGroup;
  loginFormSubmitted = false;
  loginError = false;
  loginErrorMessage = '';
  registerFormSubmitted = false;
  registerError = false;
  registerErrorMessage = '';
  showLogin = true;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public auth: AuthService,
    private localize: LocalizeRouterService,
    private router: Router,
    private toaster: ToastrService,
    public translate: TranslateService,
    private corporateService: CorporatesService,
    public dialogRef: MatDialogRef<any>,
    private merchantService: MerchantService
  ) {
  }

  ngOnInit(): void {
    this.auth.userType.subscribe(userType => {
      this.userType = userType;
    });

    this.loginForm = this.fb.group({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.registerForm = this.fb.group({
      nationalId: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  login(): void {
    let loginForm;
    const { userName, password } = this.loginForm.value;
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if (this.userType === false) {
      loginForm = {
        CorpUsername: userName,
        CorpPassword: password
      };
      this.auth.loginCorp(loginForm).subscribe((res: any) => {
        if (res.Status) {
          const translatedPath: any = this.localize.translateRoute('/corporates');
          this.dialogRef.afterClosed().pipe(
            tap(() => this.router.navigate([translatedPath])),
            first()
          ).subscribe();
          localStorage.setItem('token', res.Response.Token);
          localStorage.setItem('CorpCode', res.Response.CorpCode);
          localStorage.setItem('SegmentsList', JSON.stringify(res.Response.SegmentsList));
          localStorage.setItem('CorpName', res.Response.CorpName);
          this.corporateService.getNameCorp.next(res.Response.CorpName);
          this.corporateService.corpData.next(res.Response);
          this.toaster.success(res.Response.CorpName, this.translate.instant('Welcome'));
          this.dialogRef.close();
        } else {
          this.loginError = true;
          this.loginErrorMessage = res.ERR_Message;
        }
      });
    } else {
      loginForm = {
        Username: userName,
        Password: password,
      };
      this.auth.loginMerchant(loginForm).subscribe((res: any) => {
        if (res.Status) {
          const translatedPath: any = this.localize.translateRoute('/home');
          this.dialogRef.afterClosed().pipe(
            tap(() => this.router.navigate([translatedPath])),
            first()
          ).subscribe();
          localStorage.setItem('token', res.Response.Token);
          localStorage.setItem('V_MerchanrtName', res.Response.V_MerchanrtName);
          this.merchantService.getMerchantData.next(res.Response.V_MerchanrtName);
          this.toaster.success(res.Response.V_MerchanrtName, this.translate.instant('Welcome'));
          this.dialogRef.close();

        } else {
          this.loginError = true;
          this.loginErrorMessage = res.ERR_Message;
        }
      });
    }
  }

  register(): void {
    this.registerFormSubmitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    const { nationalId, phoneNumber, userName, password } = this.registerForm.value;

    const registerData = {
      V_NationalID: nationalId,
      V_u_phoneNumber: phoneNumber,
      UserName: userName,
      Password: password
    };

    this.auth.register(registerData).subscribe(response => {
      if (response.Status) {
        const translatedPath: any = this.localize.translateRoute('/home');
        this.dialogRef.afterClosed().pipe(
          tap(() => this.router.navigate([translatedPath])),
          first()
        ).subscribe();
        localStorage.setItem('token', response.Response.Token);
        localStorage.setItem('V_MerchanrtName', response.Response.V_MerchanrtName);
        this.merchantService.getMerchantData.next(response.Response.V_MerchanrtName);
        this.toaster.success(response.Response.V_MerchanrtName, this.translate.instant('Welcome'));
        this.dialogRef.close();
      } else {
        this.registerError = true;
        this.registerErrorMessage = response.ERR_Message;
      }
    });
  }

  get loginControls(): { [p: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  get registerControls(): { [p: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  toggleLoginRegister(): void {
    this.showLogin = !this.showLogin;

    if (this.showLogin) {
      this.loginFormSubmitted = false;
      this.registerForm.reset();
      this.registerForm.clearValidators();
      this.loginError = false;
    } else {
      this.registerFormSubmitted = false;
      this.loginForm.reset();
      this.loginForm.clearAsyncValidators();
      this.registerError = false;
    }
  }
}
