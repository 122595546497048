import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';

@Component({
  selector: 'app-our-network-items',
  templateUrl: './our-network-items.component.html',
  styleUrls: ['./our-network-items.component.scss']
})
export class OurNetworkItemsComponent implements OnInit , AfterViewInit{

  merchant:any;
  items:any;
  array:any;
  id:string;
  merchantItems:any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private ourNetworkService: OurNetworkService,
     private route: ActivatedRoute,) { }
  openBranchInMap(lat:any, lng:any) {
    if (isPlatformBrowser(this.platformId)) {
    window.open(`http://maps.google.com/maps?daddr=${lng},${lat}`);
    }
  }
  ngOnInit(): void {
    this.id =  this.route.snapshot.paramMap.get('id');
    this.merchant=history.state;
    this.items=history.state;
  this.searchMerchantByName();

  }
  searchMerchantByName() {
    this.ourNetworkService.searchMerchantBysID(this.id)
    .subscribe(res => {
      this.merchantItems = res.Response;
    this.array = this.merchantItems[0]['merchantBranches'];
    console.log('this.array',this.array);
    console.log('this.merchantItems',this.merchantItems);
    
  })
}
ngAfterViewInit() {

}
}

