import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dream2000',
  templateUrl: './dream2000.component.html',
  styleUrls: ['./dream2000.component.scss']
})
export class Dream2000Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
