import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { ErrorInterceptor } from "./error.interceptor"
import { HttpHeadersInterceptor } from "./http-headers.interceptor"
import { LoaderInterceptorInterceptor } from "./loader.interceptor"

export const httpInterceptor=[
  {provide:HTTP_INTERCEPTORS, useClass:ErrorInterceptor,multi:true},
  {provide:HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor,multi:true},
  {provide:HTTP_INTERCEPTORS, useClass:LoaderInterceptorInterceptor,multi:true},


]