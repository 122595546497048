import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMaterialModule } from '../modules/angular-matrial/angular-matrial.module';
import { SwiperModule } from 'swiper/angular';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { HomescreenComponent } from '../core/components/homescreen/homescreen.component';
import { ListButtonsComponent } from './components/list-buttons/list-buttons.component';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [ReplacePipe, SpinnerComponent, ListButtonsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizeRouterModule,
    NgbModule,
    AngularMaterialModule,
    SwiperModule,
    RouterModule,
    ToastrModule.forRoot()
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    ReplacePipe,
    AngularMaterialModule,
    SwiperModule,    
    LocalizeRouterModule,
    RouterModule,
    SpinnerComponent,
    ListButtonsComponent,
  ]
})
export class SharedModule { }
