<section class="join-our-network"
data-aos="fade-up"
data-aos-duration="2000">
  <div class="container">
  <div class="row">
      <div class="col-12 col-md-12" >
        <div class="img-box">
          <img src="assets/img/img.png" alt="Souhoola-Join-Us">
        </div>
      </div>
      </div>
      <!-- <div class="row">
      <div class="col-12 col-md-6">
        <div class="content-join-our-network">
          <div class="mb-small">
            <h2 class="heading-secondary">
             {{"joinus.Benefitsyouwillgetwhenyouwithus" | translate}}
            </h2>
        </div>
        <ul class="aspiration">
          <li>{{"joinus.Benefitsyouwillgetwhenyouwithus2" | translate}}
          </li>
          <li>{{"joinus.Benefitsyouwillgetwhenyouwithus3" | translate}}
          </li>
          <li>{{"joinus.Benefitsyouwillgetwhenyouwithus4" | translate}}
          </li>
        </ul>
        </div>
      </div>
    </div> -->
  </div>
</section>
<section class="join-us text-center"
data-aos="fade-up"
data-aos-duration="2000">
  <div class="mb-small ">
    <h2 class="heading-secondary heading-secondary-join-us"  style="color:#6F01E4;">
      {{"joinus.title" | translate}}
    </h2>
    <span class="heading-tertiary" style="color:#000;">
      {{"joinus.BecomeaSouhoolaMerchant" | translate}}
    </span>
</div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <form class="main-form"
      novalidate
      [formGroup]="joinUsForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off">
          <div class="form-group row">
            <div class="col-md-6">
            <input type="text" class="form-control"
             placeholder="{{'CommercialName'| translate}}"
             formControlName="CommercialName" name="CommercialName" id="CommercialName"
             [ngClass]="{ 'is-invalid': submitted && f.CommercialName.errors }">
             <div *ngIf="submitted && f.CommercialName.errors" class="invalid-feedback">
              <div *ngIf="f.CommercialName.errors.required">{{"joinus.CommercialNameisrequired" | translate}}</div>
          </div>
          </div>
        <!-- </div>
              <div class="form-group">  -->
                <div class="col-md-6">
                <select class="form-control form-item__element--select"
                formControlName="govName" name="govName"
                 id="Select1govName" placeholder="" required
                 [ngClass]="{ 'is-invalid': submitted && f.govName.errors }">
                  <option value="" disabled  selected hidden data-default>{{"joinus.Governorate" | translate}}</option>
                  <option *ngFor="let city of cityItem">{{city.cityName}}</option>
                </select>
                <div *ngIf="submitted && f.govName.errors" class="invalid-feedback">
                  <div *ngIf="f.govName.errors.required">{{"joinus.Governorateisrequired" | translate}}</div>
              </div></div>
                
              </div>
            <div class="form-group row">
              <div class="col-md-6"><input type="email" class="form-control" id="Input1email" required
                formControlName="fullAddress" name="fullAddress" id="fullAddress"
                 placeholder="{{'FullAddress'| translate}}"
                 [ngClass]="{ 'is-invalid': submitted && f.fullAddress.errors }">
                 <div *ngIf="submitted && f.fullAddress.errors" class="invalid-feedback">
                  <div *ngIf="f.fullAddress.errors.required">{{"joinus.FullAddressisrequired" | translate}}</div>
              </div>
            </div>
            
              
            <!-- </div>
            <div class="form-group"> -->
                <div class="col-md-6">
              <input type="text" class="form-control"  required
              formControlName="fullName" name="fullName" id="fullName"
               placeholder="{{'ContactPersonFull'| translate}}"
               [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" >
               <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
                <div *ngIf="f.fullName.errors.required">{{"joinus.FullNameisrequired" | translate}}</div>
            </div>
            </div>
          </div>
            <div class="form-group row">
              <div class="col-md-6">
              
              <input type="text" class="form-control" id="InputmobileNo" required
               placeholder="{{'ContactPersonMobileNumber'| translate}}"
              formControlName="mobileNo" name="mobileNo" id="mobileNo"
               [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }">
               <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                <div *ngIf="f.mobileNo.errors.required">{{"joinus.MobileNumberisrequired" | translate}}</div>
            </div>
          </div>
            <!-- </div>

            <div class="form-group"> -->
              <div class="col-md-6">
              <input type="text" class="form-control"
                placeholder="{{'Attachment'| translate}}" required
                [ngClass]="{ 'is-invalid': submitted && f.fileName.errors }">

              <label class="custom-file-upload">
                <input type="file"
                 class="form-control"
                 accept=".png, .jpg, .jpeg"
                formControlName="fileName"  (change)="addFile($event)" >
                <i class="fa fa-paperclip"></i>
            </label>
            <div *ngIf="submitted && f.fileName.errors" class="invalid-feedback">
              <div *ngIf="f.fileName.errors.required">{{"joinus.fileNameisrequired" | translate}}</div>
          </div>
            </div>
</div>
          <div class="form-group row">
            <div class="col-sm-2"></div>
              <div class="col-sm-8">
                <button type="submit" class="btn" style="background-color: #6F01E4;">{{"joinus.Send"| translate}}</button>
              </div>
              <div class="col-sm-2"></div>
            </div>
        </form>
      </div>
    </div>
  </div>
</section>
