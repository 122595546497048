import { ActivatedRoute} from '@angular/router';
import {Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser} from '@angular/common';
import { GetPromoSlider } from 'src/app/core/models/Promo-Slider/get-promo-slider.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OurNetworkService } from '../../services/portal/our-network.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  promos!: GetPromoSlider['Response'];
  constructor(private activatedRoute: ActivatedRoute,@Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,@Inject(PLATFORM_ID) private platformId: Object, private ourNetworkService: OurNetworkService) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      this.retreiveData();
      this.document.documentElement.classList.remove("no-scroll");
      this.translate.onLangChange.subscribe((res: LangChangeEvent) => {
        setTimeout(() => {
          this.retreiveData();
        }, 50);
      })
    }
  }
  retreiveData() {
    this.ourNetworkService.getPromotionsNetwork().subscribe(data => {
     this.promos = data['Response'];
    }
    );
  } 
  scrollSmooth(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    }
}
