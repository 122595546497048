import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title, TransferState } from '@angular/platform-browser';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { localizeBrowserLoaderFactory } from './shared/loaders/localize-browser.loader';
import { Location } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { StaticModule } from './modules/static/static.module';
import { CoreModule } from './core/core.module';
import { httpInterceptor } from './core/interceptors';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from './shared/shared.module';
import { OurNetworkModule } from './modules/our-networks/our-networks.module';
import { OfferDetailsComponent } from './core/pages/offer-details/offer-details.component';

@NgModule({
  declarations: [
    AppComponent,
    OfferDetailsComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    FileUploadModule,
    SharedModule,
    StaticModule,
    CoreModule,
    SwiperModule,
    OurNetworkModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeBrowserLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient, TransferState],
      },
      initialNavigation: true,
    }),

  ],
  providers: [httpInterceptor, Title],
  bootstrap: [AppComponent]
})
export class AppModule {
}
