<div class="overlay"></div>

<ngb-carousel style="height: 100vh; position: relative;">

  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="assets/img/home/IMAGE-min.png" alt="Souhoola second slide">
    </div>
    <div class="container carousel-caption">
      <div class="carousel-caption__Text"
           data-aos="fade-down-left"
           data-aos-duration="3000">
        <div class="heading-primary"  style="font-size: 36px;">
          {{"home.sohuolatitle" | translate}}
          <span style="font-weight:bold;"> {{"home.souhoola_bold" | translate}}</span>
          <br />
          {{"home.sohuolatitle1" | translate}}
          <br />
          {{"home.sohuolatitle2" | translate}}
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.cicapital.cicf.android.myapplication" target="_blank">
            <!--<div class="btn-liquid">class="aLiquid "
              <a>{{"home.Readmore" | translate}}</a>
            </div>-->

            <a>
              <img src="assets/img/home/Google-Play.svg" width="25%" alt="Souhoola">
              <!--<span>{{'merchant' | translate}}</span>-->
            </a>

          </a>
          <a href="https://apps.apple.com/eg/app/souhoola-%D8%B3%D9%87%D9%88%D9%84%D8%A9/id1514405177" target="_blank">
            <a>
              <img src="assets/img/home/app-store-icon.svg" width="20%" alt="Souhoola">

              <!--<span>{{'merchant' | translate}}</span>-->
            </a>
          </a>
        </div>
      </div>
      <div class="carousel-caption__Logo"
           data-aos="fade-down-right"
           data-aos-duration="3000">



        <img src="/assets/img/SouhoolaLogoHome.svg" alt="Souhoola-Logo">
      </div>

    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="assets/img/home/IMAGE2-min.png" alt="Souhoola second slide">
    </div>
    <div class="container carousel-caption">
      <div class="carousel-caption__Text"
      data-aos="fade-down-left"
      data-aos-duration="3000">
   <div class="heading-primary">
     {{"home.startBrowsingTitle" | translate}}
   </div>
   <a [routerLink]="'/contactus' | localize" class="aLiquid ">
     <div class="btn-liquid">
       <a>{{"home.StartBrowsing" | translate}}</a>
     </div>
     <div class="liquid"></div>
   </a>
 </div>
      <div class="carousel-caption__Logo"
           data-aos="fade-down-right"
           data-aos-duration="3000">
        <img src="/assets/img/SouhoolaLogoHome.svg" alt="Souhoola-Logo">
      </div>
   
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="assets/img/home/IMAGE3-min.png" alt="Souhoola third slide">
    </div>
    <div class="container carousel-caption">
      <div class="carousel-caption__Text"
      data-aos="fade-down-left"
      data-aos-duration="3000">
   <div class="heading-primary">
     {{"home.joinUsTtitle" | translate}}
   </div>
   <a [routerLink]="'/joinournetwork' | localize" class="aLiquid ">
     <div class="btn-liquid">
       <a>{{"home.joinus" | translate}}</a>
     </div>
     <div class="liquid"></div>
   </a>
 </div>
      <div class="carousel-caption__Logo"
           data-aos="fade-down-left"
           data-aos-duration="3000">
        <img src="/assets/img/SouhoolaLogoHome.svg" alt="Souhoola-Logo">
      </div>
     
    </div>

  </ng-template>
</ngb-carousel>


<div class="social-media">
  <ul class="icons-social-media"
      data-aos="fade-up"
      data-aos-duration="3000">
    <li>
      <a href="https://www.linkedin.com/company/souhoola-ci-capital/" target="_blank">
        <i class="fa fa-linkedin" aria-hidden="true"></i>
      </a>
    </li>
    <li><a href="https://www.instagram.com/souhoolaeg/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
    <li><a href="https://www.facebook.com/Souhoola/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
  </ul>
</div>
<app-features></app-features>
<!--<app-list-buttons></app-list-buttons>-->
