import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Allcities } from 'src/app/core/models/Cities/allcities.model';
import { MerchantNetworks } from 'src/app/core/models/Our-Network/merchant-networks.model';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';


@Component({
  selector: 'app-filter-bycity',
  templateUrl: './filter-bycity.component.html',
  styleUrls: ['./filter-bycity.component.scss']
})
export class FilterBycityComponent implements OnInit {

  Cities$!:  Allcities['Response'];
  dialogTitle: string;
dialogText: string;
  cityid: number;
  page : number = 1;
  filterCity!: Allcities['Response'];
  constructor(private ourNetworkService: OurNetworkService,
    private dialogRef: MatDialogRef<FilterBycityComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getAllCities();
    this.dialogTitle = this.data.dialogTitle;
    this.dialogText = this.data.dialogText;
  
   }

  getAllCities() {
      this.ourNetworkService.getAllCities().subscribe((res: Allcities) => {
       this.Cities$ = res.Response;
       this.filterCity = res.Response;

     });
   }

   getMerchantByCity(city: number) {
    this.ourNetworkService.getMerchantByCity(this.page, city)
    .subscribe((res: MerchantNetworks) => {
      this.cityid = city;
      this.dialogRef.close(this.cityid);
    })
  }

  filter(query: string) {
   this.filterCity = (query) ?
    this.Cities$.filter ((p:any) =>  p.cityName.toLowerCase().includes(query.toLowerCase())) : this.Cities$;
  }
}
