<section class="offer" *ngFor="let offeritem of offers">
  <div class="offer__img">
    <img [src]="offeritem.URL | replace : 'http://169.239.39.103:83' : 'https://dev.souhoola.net'" [alt]="offeritem.Title">
  </div>
  <div class="offer__details">
    <div class="offer__title">
      <h2>{{offeritem.Title}}</h2>
    </div>
    <br /> <br />
    <div class="offer__description">
      <p class="card-text">{{offeritem.Description}}</p>
    </div>
    <br /> <br />
    <br /> <br />

    <div class="offer__terms">
      <!--<p>* {{"TermsandConditions" | translate}} *</p>-->
    </div>

    <div class="offer__terms">
      <p class="card-text">{{offeritem.TermsAndConditions}}</p>
      <!--<p>
      · للاستمتاع بالعرض يجب تفعيل الحساب قبل اجراء المعاملة وذلك من خلال التوجه لاقرب فرع ايهاب سنتر<br>
      · مدة التقسيط ل18 شهر سارية على جميع المنتجات<br>
      · التقسيط بالسعر الرسمي للمنتج<br>
      · الحد الادني لمبلغ عملية التقسيط 1000 جنيه<br>
      · الحد الادني لمدة التقسيط 6 اشهر<br>
    </p>-->


    </div>
    <div class="offer__button">
      <h6 class="offer__heading">{{"offerAPP" | translate}}</h6>
      <div class="flex social-btns">
        <a class="social-btns__item app-btn blu-app flex vert" href="https://apps.apple.com/eg/app/souhoola-%D8%B3%D9%87%D9%88%D9%84%D8%A9/id1514405177">
          <i class="fa fa-apple"></i>
          <p>{{"Availableonthe" | translate}} <br /> <span class="big-txt">App Store</span></p>
        </a>
        <a class="social-btns__item app-btn blu-play flex vert" href="https://play.google.com/store/apps/details?id=com.cicapital.cicf.android.myapplication">
          <i class="fa fa-play"></i>
          <p>{{"Getiton" | translate}} <br /> <span class="big-txt">Google Play</span></p>
        </a>
      </div>
    </div>
    <footer>
      <div class="rounded-social-buttons">
        <a class="social-button facebook" href="https://www.facebook.com/Souhoola/" target="_blank"><i class="fa fa-facebook-f"></i></a>
        <a class="social-button twitter" href="https://www.twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a>
        <a class="social-button linkedin" href="https://www.linkedin.com/company/souhoola-ci-capital/mycompany/" target="_blank"><i class="fa fa-linkedin"></i></a>
        <a class="social-button instagram" href="https://www.instagram.com/souhoolaeg/" target="_blank"><i class="fa fa-instagram"></i></a>
      </div>
    </footer>
  </div>
</section>
