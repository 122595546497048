import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatTableExporterModule } from 'mat-table-exporter';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';


@NgModule({
  declarations: [
    LoginComponent, RegisterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    LocalizeRouterModule,
    FormsModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  exports: [
    LoginComponent, RegisterComponent
  ],
})
export class AuthenticationModule { }
