import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';
import { FilterBycityComponent } from '../filter-bycity/filter-bycity.component';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Categories } from 'src/app/core/models/Categories/categories.model';
import { MerchantNetworks } from 'src/app/core/models/Our-Network/merchant-networks.model';

@Component({
  selector: 'app-our-network-page',
  templateUrl: './our-network-page.component.html',
  styleUrls: ['./our-network-page.component.scss']
})
export class OurNetworkPageComponent implements OnInit {

  page : number = 1;
  categoryId : number;
  city : number = 0;
  categories!: Categories['Response'];
  category:any;
  flag: boolean = false;
  cityItem: number;
  fileNameDialogRef!: MatDialogRef<FilterBycityComponent>;
  filterMerchants!: MerchantNetworks['Response'];
  itemsPerPage: number;
  totalItems: any;
  previousPage: any;
  merchantName:any;
  constructor(private ourNetworkService: OurNetworkService,private translate: TranslateService,
     private route: ActivatedRoute, public dialog: MatDialog,
     @Inject(PLATFORM_ID) private platformId: Object,@Inject(DOCUMENT) private document: Document) {

      if (isPlatformBrowser(this.platformId)) {
       this.getAll();
        this.document.documentElement.classList.remove("no-scroll");
        this.getCategory();
        this.route.queryParamMap.subscribe(params => {
          this.category = params.get('category');
        })
        this.route.params.subscribe(p => { this.getAll(); })
        this.category ='';
        this.translate.onLangChange.subscribe((res: LangChangeEvent) => {
          setTimeout(() => {
            this.getCategory();
            this.RefreshItems()
          }, 50);
        })
      }
     }

    ngOnInit(): void {
    }
    getCategory() {
      this.ourNetworkService
      .getAllCategories().subscribe((res: Categories) => { this.categories = res.Response;})
    }

    getAll() {
      this.page =0;
      this.ourNetworkService.getAllItem(this.page).subscribe((res:any) => {
        this.filterMerchants = res.Response;
        this.flag =true;
      });
    }
    RefreshItems() {
      this.ourNetworkService.getAllItem(this.page).subscribe((res:any) => {
        this.filterMerchants = res.Response;
        this.flag =true;
      });
    }
    getfilterCategory(code:number) {
      this.categoryId = code;
      this.ourNetworkService.getMerchantByCityCategory(this.page,code,this.cityItem).subscribe((res: MerchantNetworks) => {
      this.filterMerchants = res.Response;
      })
    }

    openAddFileDialog() {
      this.fileNameDialogRef = this.dialog.open(FilterBycityComponent, { panelClass: 'my-custom-filter-class', data: {dialogTitle: this.page, dialogText: this.categoryId}});
      this.fileNameDialogRef.afterClosed().subscribe(cityId => {
        this.cityItem = cityId;
        this.ourNetworkService.getMerchantByCity(this.page, this.cityItem).subscribe((res:any) => {
          this.filterMerchants = res.Response;
        })
      })
    }

    loadPage(page: number) {
      if (page !== this.previousPage) {
        this.previousPage = page;
        this.loadData();
      }
    }

    loadData() {
      this.ourNetworkService.getAllItem(this.page).subscribe(res => {
        this.page - 1;
        this.filterMerchants = res.Response;

      })
      this.scrollSmooth();
    }

    scrollSmooth(){
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo({
          top: 90,
          left: 0,
          behavior: 'smooth'
        });
      }
      }

      onSubmit() {
        this.searchMerchantByName();
      }
    searchMerchantByName() {
      this.ourNetworkService.searchMerchantByName(this.merchantName, this.cityItem, this.categoryId, this.page)
      .subscribe(res => {
        this.filterMerchants = res.Response;
    })
    }
}
