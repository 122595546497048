import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SharedModule } from '../shared/shared.module';
import { JoinUsComponent } from './pages/join-us/join-us.component';
import { OffersComponent } from './pages/offers/offers.component';
import { HomescreenComponent } from './components/homescreen/homescreen.component';
import { FeaturesComponent } from './components/features/features.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { OfferItemComponent } from './pages/offer-item/offer-item.component';
import { MorshedyComponent } from './pages/morshedy/morshedy.component';
import { DubaiphoneComponent } from './pages/dubaiphone/dubaiphone.component';
import { MobilatyComponent } from './pages/mobilaty/mobilaty.component';
import { Dream2000Component } from './pages/dream2000/dream2000.component';
import { EhabcenterComponent } from './pages/ehabcenter/ehabcenter.component';
import { MiamicenterComponent } from './pages/miamicenter/miamicenter.component';
import { SpinneysComponent } from './pages/spinneys/spinneys.component';
import { TradelineComponent } from './pages/tradeline/tradeline.component';
import { XiaomiComponent } from './pages/xiaomi/xiaomi.component';
import { RaneenComponent } from './pages/raneen/raneen.component';
import { XprsComponent } from './pages/xprs/xprs.component';

@NgModule({
  declarations: [FooterComponent, NavbarComponent, JoinUsComponent,
    HomescreenComponent, OffersComponent, FeaturesComponent, OfferItemComponent, MorshedyComponent,
    DubaiphoneComponent, MobilatyComponent, Dream2000Component, EhabcenterComponent, MiamicenterComponent,
    SpinneysComponent, TradelineComponent, XiaomiComponent, RaneenComponent, XprsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationModule
  ],
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    JoinUsComponent,
    OffersComponent,
    HomescreenComponent,
    FeaturesComponent
  ],
  providers: []
})
export class CoreModule {
}
