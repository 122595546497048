<section class="contact-us">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6"  >
        <div class="mb-medium">
          <h2 class="heading-secondary">
            {{"contactus.Wedlovetohearfromyou" | translate}}
          </h2>
      </div>
        <div class="section-forms"  >
          <form class="main-form" [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <input type="name" class="form-control" id="exampleFormControlInput1"
                placeholder="{{'contactus.Name' | translate}}" formControlName="Name"  class="form-control"  maxlength="20" >
              <div  class="text-danger" *ngIf="(contactUsForm.get('Name').touched &&  contactUsForm.get('Name').invalid) || contactUsForm.get('Name').dirty ">
              <span *ngIf="contactUsForm.get('Name').errors?.minlength">{{"contactus.ValidName" | translate}}</span>
              <span *ngIf="contactUsForm.get('Name').errors?.required">{{"contactus.ValidName" | translate}}</span>
              </div>
              </div>
              <div class="form-group">
                  <input type="email" class="form-control" id="exampleFormControlInput1"
                  formControlName="Email"
                   placeholder="{{'contactus.EmailAddress' | translate}}" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
                <div  class="text-danger" *ngIf="(contactUsForm.get('Email').touched &&  contactUsForm.get('Email').invalid) || contactUsForm.get('Email').dirty ">
                  <span *ngIf="contactUsForm.get('Email').errors?.required">{{"contactus.ValidMail" | translate}}</span>
                  <span *ngIf="contactUsForm.get('Email').errors?.pattern">{{"contactus.ValidMail" | translate}}</span>
                  </div>
                </div>
                <div class="form-group">
                  <input type="name" class="form-control" id="exampleFormControlInput3"
                  formControlName="MobileNum" (keypress)="numberOnly($event)" placeholder="{{'contactus.MobileNumber' | translate}}" maxlength="11">
                    <div  class="text-danger" *ngIf="(contactUsForm.get('MobileNum').touched &&  contactUsForm.get('MobileNum').invalid) || contactUsForm.get('MobileNum').dirty ">
                      <span *ngIf="contactUsForm.get('MobileNum').errors?.required">{{"contactus.ValidPhone" | translate}}</span>
                      <span *ngIf="contactUsForm.get('MobileNum').errors?.pattern">{{"contactus.ValidPhone" | translate}}</span>
                      </div>
                </div>
                <div class="form-group">
                  <select class="form-control"  formControlName="Enquiry" id="exampleFormControlSelect1">
                    <option value="" disabled selected> {{"contactus.Select" | translate}}</option>
                    <option> {{"contactus.Enquiry" | translate}}</option>
                    <option> {{"contactus.Suggestion" | translate}}</option>
                    <option> {{"contactus.Complaint" | translate}}</option>
                  </select>
                  <div  class="text-danger" *ngIf="(contactUsForm.get('Enquiry').touched &&  contactUsForm.get('Enquiry').invalid) || contactUsForm.get('Enquiry').dirty ">
                    <span *ngIf="contactUsForm.get('Enquiry').errors?.required">{{"contactus.ErrorSelect" | translate}}</span>
                    </div>
                </div>
                <div class="form-group">
                  <input type="name" class="form-control" id="exampleFormControlInput5"
                    placeholder="{{'contactus.Attachment(optional)' | translate}}" >
                  <label class="custom-file-upload">
                    <input type="file"/>
                    <i class="fa fa-paperclip"></i>
                </label>
                </div>

              <div class="form-group">
                <textarea placeholder="{{'contactus.Message' | translate}}"  [ngClass]="{ 'is-invalid': submitted && f.Message.errors }"
                [rows]="3" formControlName="Message" class="form-control" id="exampleFormControlTextarea1" rows="3" minlength="15" maxlength="2000"></textarea>
                <div  class="text-danger" *ngIf="(contactUsForm.get('Message').touched &&  contactUsForm.get('Message').invalid) || contactUsForm.get('Message').dirty ">
                  <span *ngIf="contactUsForm.get('Message').errors?.minlength">{{"contactus.ValidMessage" | translate}}</span>
                  <span *ngIf="contactUsForm.get('Message').errors?.required">{{"contactus.ValidMessage" | translate}}</span>

                  </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-8"></div>
                  <div class="col-sm-2">
                    <button type="submit" class="btn btn-primary bgcolor btn-block" [disabled]="!contactUsForm.valid" >{{"contactus.Send" | translate}}</button>
                  </div>
                </div>
          </form>
      </div>
      </div>
      <div class="col-12 col-md-6" >
        <div class="contact-us-info">
          <div class="img-box">
            <img src="assets/img/contactus2.png" alt="">
          </div>
          <div class="overlay-box">
            <div class="m-medium">
              <h3 class="heading-secondary heading-secondary-card">
                {{"contactus.SouhoolaContactus" | translate}}
              </h3>
          </div>
          <div class="content">
            <span class="content-heading">
              {{"contactus.CustomerCareHotline" | translate}} :
            </span>
            <div class="content-details">
              15227
            </div>
          </div>
          <div class="content">
            <span class="content-heading">
              {{"contactus.AddressContactus" | translate}} :
            </span>
            <div class="content-details">
              {{"contactus.AddressContact" | translate}}
            </div>
          </div>
          <div class="content">
            <span class="content-heading" style="font-size:12px">
              {{"contactus.Foranyinquiriescontactus" | translate}} :
            </span>
            <div class="content-details">
              info@souhoola.com
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
