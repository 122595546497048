<section class="section-login">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-small">
          <h2 class="heading-secondary">
            {{(showLogin ? "loginAsMerchant":"registerAsMerchant") | translate}}
          </h2>
        </div>

        <div class="server-error" *ngIf="showLogin && loginError">{{loginErrorMessage}}</div>
        <div class="server-error" *ngIf="!showLogin && registerError">{{registerErrorMessage}}</div>

        <form *ngIf="showLogin" class="form login-forms" novalidate [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
          <div class="form-group">
            <input
              formControlName="userName"
              type="text"
              class="form-control"
              autocomplete="login-userName"
              [ngClass]="{ 'is-invalid': loginFormSubmitted && loginControls.userName.errors }"
              [placeholder]="'Username' | translate">
            <div *ngIf="loginFormSubmitted && loginControls.userName.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="loginControls.userName.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              autocomplete="login-password"
              [ngClass]="{ 'is-invalid': loginFormSubmitted && loginControls.password.errors }"
              [placeholder]="'Password' | translate">
            <div *ngIf="loginFormSubmitted && loginControls.password.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="loginControls.password.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <button type="submit" class="form-control btn btn-mainColor">{{"Login" | translate}}</button>
        </form>

        <form *ngIf="!showLogin" class="form login-forms" novalidate [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off">
          <div class="form-group">
            <input
              formControlName="nationalId"
              type="text"
              class="form-control"
              autocomplete="register-nationalId"
              [ngClass]="{ 'is-invalid': registerFormSubmitted && registerControls.nationalId.errors }"
              [placeholder]="'nationalID' | translate">
            <div *ngIf="registerFormSubmitted && registerControls.nationalId.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="registerControls.nationalId.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <input
              formControlName="phoneNumber"
              type="text"
              class="form-control"
              autocomplete="register-phoneNumber"
              [ngClass]="{ 'is-invalid': registerFormSubmitted && registerControls.phoneNumber.errors }"
              [placeholder]="'phoneNumber' | translate">
            <div *ngIf="registerFormSubmitted && registerControls.phoneNumber.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="registerControls.phoneNumber.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <input
              formControlName="userName"
              type="text"
              class="form-control"
              autocomplete="register-userName"
              [ngClass]="{ 'is-invalid': registerFormSubmitted && registerControls.userName.errors }"
              [placeholder]="'Username' | translate">
            <div *ngIf="registerFormSubmitted && registerControls.userName.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="registerControls.userName.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              autocomplete="register-password"
              [ngClass]="{ 'is-invalid': registerFormSubmitted && registerControls.password.errors }"
              [placeholder]="'Password' | translate">
            <div *ngIf="registerFormSubmitted && registerControls.password.errors" class="invalid-feedback ERR_Message">
              <div *ngIf="registerControls.password.errors.required">{{"corporates.filedRequired" | translate}}</div>
            </div>
          </div>
          <button type="submit" class="form-control btn btn-mainColor">{{"Signup" | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</section>
<div class="footer">
  <div *ngIf="showLogin">{{"doNotHaveAccount" | translate}} <span (click)="toggleLoginRegister()">{{"clickHere" | translate}}</span></div>
  <div *ngIf="!showLogin">{{"alreadyRegistered" | translate}} <span (click)="toggleLoginRegister()">{{"clickHere" | translate}}</span></div>
</div>
