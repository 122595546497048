import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorporatesService {
  corpData = new BehaviorSubject<any>(null);
  getNameCorp = new Subject();

  constructor(private http: HttpClient) { }

  getCorpData() {
    return this.http.get(`${environment.apiGetCorpData}`);
  }
  deleteCorpData(id: any) {
    return this.http.post(`${environment.apiDeleteCorpData}`,
    {
      "EmpID":id
    })
  }
  addCorpData(data: any) {
    return this.http.post(`${environment.apiAddCorpData}`,data)
  }
  updateCorpData(id,data: any) {
    return this.http.post(`${environment.apiUpdateCorpData}`,{
      ...data,
      EmpID: id
    })

  }
  uploadCorpData(file:any) {
    return this.http.post(`${environment.apiUploadCorpData}`,file)
  }
  uploadCorpLogo(img: any) {
    return this.http.post(`${environment.apiUploadCorpLogo}`,img);
  }
  getCorpLogo() {
    return this.http.get(`${environment.GetCorpLogo}`);
  }
}
