<br>
<br>
<div class="career">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mb-small">
          <h2 class="heading-secondary">
            {{"careers.WhySouhoola?" | translate}}
          </h2>
      </div>
      </div>
      <div class="col-md-12">
        <p class="title-box">
          {{"careers.whysohuolatitle" | translate}}
        </p>

      </div>

      <div class="our-value">
        <div class="col-12">
          <div class="mb-small">
            <h2 class="heading-secondary">
              {{"careers.OurValues" | translate}}
            </h2>
        </div>
        </div>
        <div class="col-md-12">
          <p class="title-box">
            <strong>{{"careers.Commitment" | translate}}</strong> : {{"careers.Commitmentd" | translate}}
          </p>
        </div>
        <div class="col-md-12">
          <p class="title-box">
            <strong>{{"careers.HonestyandFairness" | translate}} </strong> : {{"careers.HonestyandFairnessd" | translate}}
          </p>
        </div>
<div class="col-md-12">
  <p class="title-box">
    <strong>{{"careers.Enterprise" | translate}}</strong>: {{"careers.Enterprised" | translate}}
  </p>
</div>

      </div>

      <div class="col-md-12 box-suitcase">
          <div class="details">
            <div class="img-box">
              <img src="assets/img/suitcase.png" alt="">
            </div>
            <div class="details-box">
                <p>{{"careers.CV" | translate}}:<Br> <a href="mailto:recruitment@souhoola.com">recruitment@souhoola.com</a> </p>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

