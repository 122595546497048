<br />
<div class="our-product"
data-aos="fade-up"
     data-aos-duration="2000">
     <div class="container">
      <div class="section-steps" >
        <div class="mb-medium ">
          <h2 class="heading-secondary "> <span style="font-weight:bold;"> 
            {{"ourproduct.Join" | translate}} {{"ourproduct.Souhoola" | translate}} {{"ourproduct.familyin3easysteps" | translate}} :
          </span>

          </h2>
      </div>
        <div class="row">
        <div class="col-md-4 box-feature" >
          <div class="img-box">
            <img src="https://cdn0.iconfinder.com/data/icons/ringtone-music-instruments/512/cloud-technology-server-data-transfer-storage-blue-round-512.png" alt="">
          </div>
          <div class="info">
            <h5>{{"ourproduct.DownloadtheApp" | translate}}</h5>
          </div>
          <img  class="arrow-right" src="assets/img/ourproducts/Group 1021-min.png" alt="">
        </div>
        <div class="col-md-4 box-feature" >
          <div class="img-box">
          <img src="https://image.flaticon.com/icons/png/512/189/189093.png" alt="">
          </div>
          <div class="info">
            <h5>{{"ourproduct.ConfirmyourLimit" | translate}}</h5>
          </div>
        </div>
        <div class="col-md-4 box-feature" >
          <div class="img-box">
            <img src="assets/img/ourproducts/cart.svg" alt="">

            </div>
          <div class="info">
            <h5>{{"ourproduct.StartBuyingImmediately" | translate}}</h5>
          </div>
          <img  class="arrow-left" src="assets/img/ourproducts/Group 1027-min.png" alt="">

        </div>
      </div>
      </div>
    </div>
</div>
<section class="our-products" data-aos="fade-up" data-aos-duration="2000">
  <div class="container">
    <div class="row">
      <div class="souhoola-elite ">
        <div class="mobile-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mobile-img">
                            <img src="assets/img/home/MobileST-min.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="mobile-title"> 
                            <strong >{{"ourproduct.ConsumerGoodsFinancing" | translate}}</strong> <br>
                          <div class="content-mobile">
                            <p class="content-title-details">
                              {{"ourproduct.ConsumerGoodsFinancing1" | translate}}
                            </p>
                          </div>
                            <div class="content-mobile">
                                <span class="content-mobile-heading">
                                  <span class="num">1</span>
                                   {{"ourproduct.ConsumerGoodsFinancing2" | translate}}
                                   </span>
                                <p class="content-title-details">
                                   {{"ourproduct.ConsumerGoodsFinancing3" | translate}}
                                   <a href="https://apple.co/3qFIinF">App store </a>
                                   <a href="https://bit.ly/3zeMqz5">- Google play</a>
                                  </p>

                            </div>
                            <div class="content-mobile">
                                <span  class="content-mobile-heading">
                                  <span class="num">2</span>
                                  {{"ourproduct.ConsumerGoodsFinancing4" | translate}}
                                  </span>
                                <p class="content-title-details"> {{"ourproduct.ConsumerGoodsFinancing5" | translate}}</p>
                            </div>
                            <div class="content-mobile">
                              <span  class="content-mobile-heading">
                                <span class="num">3</span>
                                {{"ourproduct.ConsumerGoodsFinancing6" | translate}}
                              </span>
                              <p class="content-title-details"> {{"ourproduct.ConsumerGoodsFinancing7" | translate}}  </p>
                          </div>
                            <div class="content-mobile">
                                <span  class="content-mobile-heading">
                                  <span class="num">4</span>
                                  {{"ourproduct.ConsumerGoodsFinancing8" | translate}}
                                </span>
                                <p class="content-title-details"> {{"ourproduct.ConsumerGoodsFinancing9" | translate}}  </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="delivery-channel-section">
          <div class="container ">
              <div class="row">
                  <div class="col-12">
                      <div class="heading-services">
                          <h2 >{{"ourproduct.OurDeliveryChannels" | translate}}</h2>
                      </div>
                  </div>
                  <div class="col-12 col-md-6">
                      <div class="channel-title text-center">
                        <img src="../../../../../assets/img/ourproducts/mobile App img.svg" style="width: 200px;"> 
                           <!-- <span  class="channel-icon"><i class="fa fa-mobile" aria-hidden="true"></i></span> -->
                          <h3 class="textdel">{{"ourproduct.OurDeliveryChannels1" | translate}}</h3>
                      </div>
                  </div>
                  <div class="col-12 col-md-6">
                      <div class="channel-title text-center">
                        <img src="../../../../../assets/img/ourproducts/buy online img.svg" style="width: 200px;">
                          <!-- <span class="channel-icon"><i class="fa fa-laptop" aria-hidden="true"></i></span> -->
                          <h3 class="textdel" >{{"ourproduct.OurDeliveryChannels2" | translate}}</h3>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </div>
  </div>
</section>
