<div class="container">
  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="assets/img/ournetworks/BANNER2.svg" alt="First slide">
        </div>
      </div>
    </div>
</div>
<div class="badget-logo" *ngFor="let item of merchantItems">
      <div class="circle">
          <img
          [src]="item.merchantLogo | replace : 'http://169.239.39.129:8890' : 'https://images.souhoola.org'"   [alt]="item.merchantName" >
      </div>
</div>
  <div class="location-section">
          <div class="container">
              <div class="row">
              <div class="col-md-6 o-2" *ngFor="let item of array">
                  <div  class="address"  
                  (click)="openBranchInMap(item.branchLocationLat,item.branchLocationLong)">
                    <div class="content">
                      <div class="icon">
                        <img src="assets/img/location-pin-min.png" alt="">
                      </div>
                      <div class="texts">
                        <h5>{{item.branchName}}</h5>
                        <h5>{{item.branchAddress}} </h5>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>