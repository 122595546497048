<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-6   col-lg-2  footer-col   logo-footer footer__logo">
        <img src="assets/img/home/Group 3100-min.png" alt="Logofooter" width="95%">
      </div>
      <div class="col-6   col-lg-2 footer-col  footer__item">
        <h4>{{"Company" | translate}}</h4>
        <ul class="list">
          <li class="list__item"><a [routerLink]="'/aboutus' | localize"  >{{"aboutus.title" | translate}} </a></li>
          <li class="list__item"><a [routerLink]="'/joinournetwork' | localize"  >{{"footer.JoinOurNetwork" | translate}}</a></li>
          <li class="list__item"><a [routerLink]="'/ournetwork' | localize"  >{{"footer.OurNetwork" | translate}}</a></li>
          <li class="list__item"><a [routerLink]="'/ourproducts' | localize"  >{{"ourproduct.title" | translate}}</a></li>
          <!-- <li class="list__item"><a [routerLink]="'/offers' | localize"  >{{"offers.title" | translate}}</a></li> -->

        </ul>
      </div>
      <div class="col-6   col-lg-2 footer-col footer__item">
        <h4>{{"GetHelp" | translate}}</h4>
        <ul class="list list--second">
          <li class="list__item"><a  [routerLink]="'/faqs' | localize" >{{"faqs.title" | translate}}</a></li>
          <li class="list__item"><a [routerLink]="'/news' | localize" >{{"news.title" | translate}}</a></li>
          <li class="list__item"><a  [routerLink]="'/career' | localize" >{{"careers.title" | translate}}</a></li>
          <li class="list__item"><a  [routerLink]="'/contactus' | localize" >{{"contactus.contactusfooter" | translate}}</a></li>

        </ul>
      </div>
      <div class="col-6   col-lg-2  footer-col footer__item">
        <h4>{{"contactus.contactusfooter" | translate}}</h4>
        <ul class="list list--third">
          <li class="list__item"><a href="tel:+15227">{{"footer.hotLinefooter" | translate}} : 15227</a></li>
          <li class="list__item"><a href = "mailto: info@souhoola.com">{{"footer.mailFooter" | translate}}: {{"footer.mailContentFooter" | translate}}</a></li>
          <li class="list__item"><a href="https://maps.app.goo.gl/F2M34YAZEiZMDRSFA" target="_blank" rel="noopener">{{"footer.addressFooter" | translate}} : {{"footer.addressFooterMain" | translate}}</a></li>
        </ul>
      </div>
      <div class="col-6   col-lg-2  footer-col footer__item">
      <div class="download-section">
        <img  class="googleplay" src="/assets/img/qrcode.png" alt="Souhoola QrCode">
        <h3 class="download-your-app">{{"footer.Downloadourapp" | translate}}</h3>
        <div class="download-app">
          <a href="https://apps.apple.com/eg/app/souhoola-%D8%B3%D9%87%D9%88%D9%84%D8%A9/id1514405177" target="_blank" rel="noopener">
            <img src="/assets//img/appstoresouhoola.png" alt="App Store Sohuoola">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.cicapital.cicf.android.myapplication" target="_blank" rel="noopener">
            <img  src="/assets//img/googleplaysouhoola.png" alt="Google Play Souhoola">
          </a>
        </div>

      </div>
      </div>
    </div>
  </div>
  <div class="footer footer__acopyright">
      <div class="container">
          <div class="row">
              <div class="col-sm-6">
                  <span class="Copyright">{{"footer.Copyright" | translate}} © CI Consumer Finance, Souhoola</span>
              </div>
              <div class="col-sm-6">
                  <!-- <span class="Powered">{{"footer.SubsidiaryOf" | translate}} CI Capital</span> -->
              </div>
          </div>
      </div>
  </div>
</footer>
