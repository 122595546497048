import { Component, Inject, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { CorporatesService } from 'src/app/core/services/corporates/corporates.service';
import { filter } from 'rxjs/operators';
import { MerchantService } from '../../services/merchants/merchant.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../authentication/components/login/login.component';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  locales = this.localizeRouterService.parser.locales;
  currentUrl = '';
  shortCutname: any;
  currentLang: string;
  public isMenuCollapsed = true;
  isHomePage = false;
  dialogRef: any;


  constructor(
    public dialog: MatDialog,
    private localizeRouterService: LocalizeRouterService, private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService, private localize: LocalizeRouterService, private merchantService: MerchantService,
    public corpService: CorporatesService, private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.currentLang = localStorage.getItem('currentLang') || 'en';
    this.Showdiv();
    this.setCurrentUrl();
    this.shortCutname = localStorage.getItem('CorpName');
    this.shortCutname = localStorage.getItem('V_MerchanrtName');
    this.merchantService.getMerchantData.subscribe(merchantName => {
      this.shortCutname = merchantName
    })
    this.corpService.getNameCorp.subscribe(corpName => {
      this.shortCutname = corpName;
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.setCurrentUrl();
    });
  }

  eventNoScroll() {
    if (this.isMenuCollapsed) {
      this.document.documentElement.classList.add("no-scroll");
    } else {
      this.document.documentElement.classList.remove("no-scroll");
    }

  }
  Showdiv(){}

  isCollapsed() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    // this.corpService.isHome.next(true);
  }
  isLoggedIn() {
    return this.auth.isLoggedIn();
  }
  logout() {
    this.auth.userLogout();
    let translatedPath: any = this.localize.translateRoute('/home');
    this.router.navigate([translatedPath]);
  }
  private setCurrentUrl(): void {
    this.currentUrl = this.router.url
      .replace('/' + this.localizeRouterService.parser.currentLang, '')
      .split('?')[0];
    localStorage.setItem('currentLang', this.localizeRouterService.parser.currentLang);
    localStorage.setItem('currentUrl', this.currentUrl);

  }
  changeLanguage(locale: string): void {
    this.translateService.use(locale);
    this.currentLang = locale;
    localStorage.setItem('currentLang', this.currentLang);
  }
  openMerchant(): void {
    this.auth.userType.next(true);
    this.openDialog();
  }
  openDialog(): void {
    this.dialogRef = this.dialog.open(LoginComponent, { panelClass: 'my-custom-login-class' });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.router.navigate(['../'], { relativeTo: this.route }).then();
    });
  }
}
