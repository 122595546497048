import { Component, Inject, PLATFORM_ID, OnInit, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LoadingServiceService } from './core/services/loadingservice/loading-service.service';
import { ActivatedRoute, NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { RouterEvent } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { NavigationCancel } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Title } from '@angular/platform-browser';
import { CanonicalService } from './shared/services/canonical.service';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit   {
  title;
  loading: boolean = false;
  currentLang:string;
  currentUrl:string;
  show: boolean = true;
  constructor(private _router: Router,private metaTagService: Meta,private canonicalService: CanonicalService,
    private translateService: TranslateService,private titleService: Title,public _loading: LoadingServiceService,
    private activatedRoute : ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2)
    {

       _router.events.pipe(
        filter(event => event instanceof NavigationStart)  
      ).subscribe((event: NavigationStart) => {
        event.url.includes('/offers/') ? this.show = false : this.show = true;
       // event.url.includes('/home/') ? this.navhome = false : this.show = true;
      }); 
      
      this._loading.isLoading.next(true);
        this.translateService.stream('DIR').subscribe(dir => {
        this.directionChanged(dir);
      });
    if (isPlatformBrowser(this.platformId)) {
        AOS.init()
        setTimeout(() => {
        this._loading.isLoading.next(false);
          }, 1000);
     }
  }

  ngOnInit(): void {
    this._router.url.includes('offers/') ? this.show = false : this.show = true;

    this.canonicalService.setCanonicalURL();
    this.currentLang = "en";//localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    this.currentUrl = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    this._loading.isLoading.subscribe((v) => { this.loading = v;})
    this.document.documentElement.classList.remove("no-scroll");
    this.gethrefLang();
    this.metaTagService.addTags([
      { name: 'keywords', content: this.translateService.instant('metaTags.keywords') },
      {name: 'description', content: this.translateService.instant('metaTags.Desc')},
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: this.translateService.instant('metaTags.author') },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);   
  }

  gethrefLang() {
   this.currentLang = "en";//localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
   this.currentUrl = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    const linkElt = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElt, 'rel', 'alternate');
    this.renderer.setAttribute(linkElt, 'hreflang', this.currentLang);
    this.renderer.setAttribute(linkElt, 'href', `https://www.souhoola.com/${this.currentLang+this.currentUrl}`);
    this.renderer.appendChild(this.document.head, linkElt);
  }
  private directionChanged(dir: string): void {
    const htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    htmlTag.dir = dir === 'rtl' ? 'rtl' : 'ltr';
  }
}
