<nav class="navbar navbar-expand-lg navbar-light"
     [ngClass]="{'bgMenu': !isMenuCollapsed}"
     [ngClass]="{'inHome': rla.isActive}">
  <!--,'NonebgMenu': merchantService.isHome | async-->

  <div class="container margin-topsm">
  
      <!-- Left Side-->
      <div class="custom-style collapse navbar-collapse order-lg-1 order-3 navigation-menu"
           [ngbCollapse]="isMenuCollapsed">

        <div class="navbar-collapse-custom navbar-collapse-custom__Left">
          <a [routerLink]="'/home' | localize">
            <img class="imgename" alt="LogoSouhoola" height="30px">
          </a>
          <!--  <ul class="navbar-nav navbar-nav-right ">
        <li class="nav-item nav-item-hover"
            data-toggle="collapse" data-target=".navigation-menu"
            aria-controls=".navigation-menu"
            aria-label="Toggle navigation">
          <a class="nav-link" (click)="isMenuCollapsed = true" #rla="routerLinkActive" [routerLink]="'/home' | localize" routerLinkActive="active">
            <span>{{"home.title" | translate}}</span>
          </a>
        </li>
        <li class="nav-item nav-item-hover"
            data-toggle="collapse" data-target=".navigation-menu"
            aria-controls=".navigation-menu"
            aria-label="Toggle navigation">
          <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/aboutus' | localize" routerLinkActive="active">
            <span>{{"aboutus.title" | translate}}</span>
          </a>
        </li>
        <li class="nav-item nav-item-hover"
            data-toggle="collapse" data-target=".navigation-menu"
            aria-controls=".navigation-menu" aria-label="Toggle navigation">
          <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/ourproducts' | localize" routerLinkActive="active">
            <span>{{"ourproduct.title" | translate}}</span>
          </a>
        </li>
        <li class="nav-item nav-item-hover"
            data-toggle="collapse" data-target=".navigation-menu"
            aria-controls=".navigation-menu" aria-label="Toggle navigation">
          <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/ournetwork' | localize" routerLinkActive="active">
            <span>{{"footer.OurNetwork" | translate}}</span>
          </a>
        </li>
      </ul>-->
        </div>
      </div>
      <!-- Left Side-->
      <!--<a class="navbar-brand order-1" [routerLink]="'/home' | localize">
    <img src="assets/img/home/Group 3098.png" alt="LogoSouhoola" width="100px">
  </a>-->

      <button id="myDIV" class="navbar-toggler menu-btn order-2" type="button" (click)="eventNoScroll()" (click)="isMenuCollapsed = !isMenuCollapsed">
        <div class="menu-btn__burger">
        </div>
      </button>
      <!-- Right Side-->
      <!--<div class="collapse navbar-collapse order-lg-3 order-4 navigation-menu right-sideItems"
     [ngbCollapse]="isMenuCollapsed">
  <div class="navbar-collapse-custom navbar-collapse-custom__right">
    <ul class="navbar-nav navbar-nav-left ">
      <li class="nav-item nav-item-hover"
          data-toggle="collapse" data-target=".navigation-menu"
          aria-controls=".navigation-menu" aria-label="Toggle navigation">
        <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/joinournetwork' | localize" routerLinkActive="active"><span>{{"footer.JoinOurNetwork" | translate}}</span></a>
      </li>
      <li class="nav-item nav-item-hover"
          data-toggle="collapse" data-target=".navigation-menu"
          aria-controls=".navigation-menu" aria-label="Toggle navigation">
        <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/news' | localize" routerLinkActive="active"><span>{{"news.title" | translate}}</span></a>
      </li>
      <li class="nav-item nav-item-hover"
          data-toggle="collapse" data-target=".navigation-menu"
          aria-controls=".navigation-menu"
          aria-label="Toggle navigation">
        <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/faqs' | localize" routerLinkActive="active"><span>{{"faqs.title" | translate}}</span></a>
      </li>

      <li class="nav-item  nav-item-hover" *ngIf="!isLoggedIn()"
          data-toggle="collapse" data-target=".navigation-menu"
          aria-controls=".navigation-menu" aria-label="Toggle navigation">
        <a class="nav-link nav-link-callus">
          <span class="tel" href="tel:+15227">15227</span>
        </a>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn()">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-merchantname" id="dropdownBasic1" ngbDropdownToggle>
            <img class="icon-img-personal" src="/assets/img/Icon awesome-user-circle-min.png" alt="merchantname">
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">-->
      <!--<button ngbDropdownItem>
    <a [routerLink]="'/corporates' | localize" alt="corporate">{{"corporate" | translate}}</a>
  </button>-->
      <!--<div class="customer-info">{{shortCutname}}</div>
              <button ngbDropdownItem (click)="logout()">{{"logout" | translate}}</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>-->
      <!-- Right Side-->

      <div class="collapse navbar-collapse order-lg-3 order-4 navigation-menu right-sideItems "
           [ngbCollapse]="isMenuCollapsed">
        <div class="navbar-collapse-custom navbar-collapse-custom__right">
          <ul class="navbar-nav navbar-nav-right ">
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu"
                aria-label="Toggle navigation">
              <a class="nav-link"   (click)="isMenuCollapsed = true" #rla="routerLinkActive" [routerLink]="'/home' | localize" routerLinkActive="active">
                <span>{{"home.title" | translate}}</span>
              </a>
            </li>
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu"
                aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/aboutus' | localize" routerLinkActive="active">
                <span>{{"aboutus.title" | translate}}</span>
              </a>
            </li>
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/ourproducts' | localize" routerLinkActive="active">
                <span>{{"ourproduct.title" | translate}}</span>
              </a>
            </li>
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/ournetwork' | localize" routerLinkActive="active">
                <span>{{"footer.OurNetwork" | translate}}</span>
              </a>
            </li>

            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/joinournetwork' | localize" routerLinkActive="active"><span>{{"footer.JoinOurNetwork" | translate}}</span></a>
            </li>
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/news' | localize" routerLinkActive="active"><span>{{"news.title" | translate}}</span></a>
            </li>
            <li class="nav-item nav-item-hover"
                data-toggle="collapse" data-target=".navigation-menu"
                aria-controls=".navigation-menu"
                aria-label="Toggle navigation">
              <a class="nav-link" (click)="isMenuCollapsed = true" [routerLink]="'/faqs' | localize" routerLinkActive="active"><span>{{"faqs.title" | translate}}</span></a>
            </li>

            <li class="nav-item  nav-item-hover" *ngIf="!isLoggedIn()"
            data-toggle="collapse" data-target=".navigation-menu"
            aria-controls=".navigation-menu" aria-label="Toggle navigation">
          <a class="nav-link sty">

            <span class="tel" href="tel:+15227">15227</span>
          </a>
        </li>
            <li class="nav-item  nav-item-hover" data-toggle="collapse" data-target=".navigation-menu" aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <a href="https://portal.souhoola.com/" target="_blank"  class="nav-link btn btn-success border-0" style="background-color: #35DE75; color: white;">
                <!--(click)="openMerchant()"-->
                <span class="newcolor"> <i class="fa fa-store"></i></span>&nbsp;{{'merchant' | translate}}
                <!--<img src="assets/img/home/Merch.svg" width="70%" alt="Souhoola">-->
                <!--<span>{{'merchant' | translate}}</span>-->



              </a>
            </li>
          
            <!-- <li class="nav-item  nav-item-hover" data-toggle="collapse" data-target=".navigation-menu" aria-controls=".navigation-menu" aria-label="Toggle navigation">
              <div class="container-lang">
                <div class="menu-btn__burger">
                  <a class="btn btn-white arab  lang-style " rel="alternate" hreflang="ar" (click)="changeLanguage('ar')" queryParamsHandling="merge"
                     routerLink="/ar/{{currentUrl}}" [ngClass]="currentLang === 'en' ? 'active2' : 'active1'">Ar</a>
                  <a class=" btn btn-white english lang-style  text-success border-success" rel="alternate" hreflang="en" (click)="changeLanguage('en')" queryParamsHandling="merge"
                     routerLink="/en/{{currentUrl}}" [ngClass]="currentLang === 'ar' ? 'active2' : 'active1'">En</a>
                </div>
              </div>
            </li> -->
        
          </ul>  
          
        </div>
      </div>

      <hr>
  </div>
  <!--<div class="container-lang"
       [ngClass]="!isMenuCollapsed ? 'hide' : 'show'">
    <div class="group-lang">
      <a class="arab lang-style" rel="alternate" hreflang="ar" (click)="changeLanguage('ar')" queryParamsHandling="merge"
         routerLink="/ar/{{currentUrl}}" [ngClass]="currentLang === 'en' ? 'active2' : 'active1'">Ar</a>
      <a class="english lang-style" rel="alternate" hreflang="en" (click)="changeLanguage('en')" queryParamsHandling="merge"
         routerLink="/en/{{currentUrl}}" [ngClass]="currentLang === 'ar' ? 'active2' : 'active1'">En</a>
    </div>
  </div>-->
 
</nav>

