import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { GetPromoSlider } from 'src/app/core/models/Promo-Slider/get-promo-slider.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider-our-network',
  templateUrl: './slider-our-network.component.html',
  styleUrls: ['./slider-our-network.component.scss']
})
export class SliderOurNetworkComponent implements OnInit {

  promos!: GetPromoSlider['Response'];
  constructor(private ourNetworkService: OurNetworkService,config: NgbCarouselConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    // customize default values of carousels used by this component tree
    if (isPlatformBrowser(this.platformId)) {
    this.getPromotionsSlider();
      config.interval = 2000;
      config.wrap = true;
      config.keyboard = true;
      config.pauseOnHover = true;
      config.showNavigationArrows = true;
      config.showNavigationIndicators = false;
    }

}
  ngOnInit(): void {

  }
  gotoDetails(i: string) {
    debugger;
    this.router.navigate(['/new-offers']);  // define your component where you want to go
  }
  getPromotionsSlider() {
    this.ourNetworkService.getPromotionsNetwork().subscribe((res: any) => {
      this.promos = res.Response;
    });
  }
}
