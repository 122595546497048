<ngb-carousel id="carousel">
  <ng-container  *ngFor="let promo of promos; let i = index" class="carousel-item" >
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img
        src="{{ promo.URL | replace : 'http://169.239.39.103:83' : 'https://dev.souhoola.net' }} "
        alt="Souhoola first slide" [routerLink]="['./../offers' | localize, promo.Id]">
      </div>
    </ng-template>
  </ng-container>
</ngb-carousel>
