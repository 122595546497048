
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import AOS from 'aos';
@Component({
  selector: 'app-homescreen',
  templateUrl: './homescreen.component.html',
  styleUrls: ['./homescreen.component.scss']
})
export class HomescreenComponent implements OnInit {
  active = 1;
  currentLang: string;
  showdiv:boolean=true;
  constructor(public dialog: MatDialog,private auth: AuthService,config: NgbCarouselConfig,
    @Inject(DOCUMENT) private document: Document,
  @Inject(PLATFORM_ID) private platformId: Object) {
        // customize default values of carousels used by this component tree
    if (isPlatformBrowser(this.platformId)) {
      config.interval = 3000;
      config.wrap = true;
      config.keyboard = true;
      config.pauseOnHover = true;
      config.showNavigationArrows = false;
      config.showNavigationIndicators = true;
      AOS.init();
    }
   }

  ngOnInit(): void {
    this.show();
  }
  isLoggedIn() {
    return this.auth.isLoggedIn();
   }
   logout() {
     return this.auth.userLogout();
   }
   show()
   {
    debugger;
    this.currentLang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    if (this.currentLang=='en')
    {
      this.showdiv = true;
    }
    else  if (this.currentLang=='ar')
    {
      this.showdiv = false;
    }

   }
}
