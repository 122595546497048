import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobilaty',
  templateUrl: './mobilaty.component.html',
  styleUrls: ['./mobilaty.component.scss']
})
export class MobilatyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
