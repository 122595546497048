import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
  @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      this.document.documentElement.classList.remove("no-scroll");
    }


  }
  scrollSmooth(){
    window.scrollTo({
      top: 20,
      left: 0,
      behavior: 'smooth'
    });
    }
}
