import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(public dialog: MatDialog) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      request = request.clone({
        setHeaders: {
          Authorization:  `Bearer ${localStorage.getItem('token')}`,
          "lang":localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE') ?? 'en',
          "OS":"Portal"
        }
      });
  return next.handle(request);
  }
  }

