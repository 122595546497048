<br /><section class="news-section"
data-aos="fade-up"
     data-aos-duration="2000">
  <div class="container">
    <div class="mb-medium">
      <h2 class="heading-secondary">
        {{"news.LatestNews" | translate}}
      </h2>
  </div>
    <div class="row">
        <div class="col-12 col-md-6" >
            <div class="card">
                <img class="card-img-top" src="assets/img/news/Certficate-min.png" alt="Card image cap">
                <div class="card-body">
                    <div class="card-title">
                        <h4>{{"news.LatestNews1" | translate}}</h4>
                        <p class="card-text">
                          {{"news.LatestNews2" | translate}}
                        </p>
                    </div>

                </div>
              </div>
        </div>
        <div class="col-12 col-md-6" >
            <div class="card">
                <img class="card-img-top" src="assets/img/news/Egypt Map-min.png" alt="Card image cap">
                <div class="card-body">
                    <div class="card-title">
                        <h4>{{"news.LatestNews3" | translate}}</h4>
                        <p class="card-text">
                          {{"news.LatestNews4" | translate}}
                    </div>
                </div>
              </div>
        </div>
        <div class="col-12 col-md-6" >
            <div class="card">
                <img class="card-img-top" src="assets/img/news/Hyper Two-min.png" alt="Card image cap">
                <div class="card-body">
                    <div class="card-title">
                        <h4>{{"news.LatestNews5" | translate}}</h4>
                        <p class="card-text">
                          {{"news.LatestNews6" | translate}}
                        </p>
                    </div>

                </div>
              </div>
        </div>
        <div class="col-12 col-md-6" >
            <div class="card">
                <img class="card-img-top" src="assets/img/news/Shop Online-min.png" alt="Card image cap">
                <div class="card-body">
                    <div class="card-title">
                        <h4>{{"news.LatestNews7" | translate}}</h4>
                        <p class="card-text">
                          {{"news.LatestNews8" | translate}}
                        </p>
                    </div>
                </div>
              </div>
        </div>
    </div>
</div>
</section>


