
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Categories } from '../../models/Categories/categories.model';
import { GetPromoSlider } from '../../models/Promo-Slider/get-promo-slider.model';
import { Allcities } from '../../models/Cities/allcities.model';
import { MerchantNetworks } from '../../models/Our-Network/merchant-networks.model';
import { NumberLiteralType } from 'typescript';


@Injectable({
  providedIn: 'root'
})
export class OurNetworkService {

  constructor(private http: HttpClient) { }
  getAllCategories() {
    return this.http.get<Categories>(`${environment.apiCategory}`)
   }

   getCategoryItem(pageNumber: number, code: number) {
     return this.http.post<MerchantNetworks>(`${environment.apiUrl}`,
     {
      "categoryID" : code,
      "pageNo":pageNumber,
      "pageSize":24
    })
   }
   getAllItem(pageNumber: number) {
     return this.http.post<MerchantNetworks>(`${environment.apiUrl}`,
       {
          "pageNo":pageNumber,
          "pageSize":24
      })
   }

   getMerchantByCity(pageNumber: number,city: number) {
       return this.http.post<MerchantNetworks>(`${environment.apiUrl}`,
       {
          "cityID" : city,
          "pageNo":pageNumber,
          "pageSize":24
      })
   }
   getMerchantByCityCategory(pageNumber: number,code: number,city: number) {
     return this.http.post<MerchantNetworks>(`${environment.apiUrl}`,
     {
        "cityID" : city,
        "categoryID" : code,
        "pageNo":pageNumber,
        "pageSize":24
    })
 }

  searchMerchantByName(
    merchantName : string,
      cityID : number,
      categoryID : number,
      pageNo: number
  ) {
    return this.http.post<MerchantNetworks>(`${environment.apiUrl}`, {

          merchantName : merchantName,
          cityID : cityID,
          categoryID : categoryID,
          pageNo: pageNo,
          pageSize:515

    })
  }
  searchMerchantBysID(
    merchantName : string
  ) {
    return this.http.post<MerchantNetworks>(`${environment.apiUrl}`, {

          merchantName : merchantName,
          pageSize:515
    })
  }
  getPromotionsNetwork() {
    return this.http.get<GetPromoSlider>(`${environment.apiPromotions}`);
  }
  getPromotionsById(id: any) {
    return this.http.get<GetPromoSlider>( `${environment.apiPromotionsById +'/?id='+ id}`)
}


  getAllCities() {
    return this.http.get<Allcities>(`${environment.apiCities}`);
  }

  JoinUS(data:any) {
    return this.http.post(`${environment.JoinUs}`,data);
   }

}
