<div class="top-mangement"
data-aos="fade-up"
     data-aos-duration="2000">
  <div class="container">
    <div class="mb-medium">
      <h2 class="heading-secondary">
        {{"aboutus.TopMangement" | translate}}
      </h2>
  </div>
    <div class="container">
      <swiper
      [ngClass]="currentLang == 'ar' ? 'ltr' : 'ltr'"
      (mouseenter)="stopSwip($event)" (mouseleave)="startSwip($event)"
      [slidesPerView]="1"
      [spaceBetween]="10"
      [loop]="true"
      #newSwiper
      [autoplay]="{
        delay: 2500,
        disableOnInteraction: true
      }"
      [pagination]="{
        clickable: true
      }"
      [breakpoints]="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50
        }
      }"
      class="mySwiper">
        <ng-template swiperSlide>
          <div class="card middle" >
            <div class="front" lazy-load-images>

              <img src="assets/img/aboutus/khaledyaken.png" alt="khaledyaken"> 
              <div class="front-title">
                <span>{{"aboutus.ChiefOperatingOfficer-(COO)" | translate}}<Br> {{"aboutus.CIConsumerFinance" | translate}}</span>
                <h3>{{"aboutus.KhaledYaken" | translate}}</h3>
              </div>
            </div>
            <div class="back">
              <div class="back-content middle">
                <h2>{{"aboutus.KhaledYaken" | translate}}</h2>
                <span>{{"aboutus.ChiefOperatingOfficer-(COO)1" | translate}}</span>
              </div>
            </div>
          </div>
          </ng-template>
        <ng-template swiperSlide>
          <div class="card middle" >
            <div class="front" >
              <img src="/assets/img/aboutus/mohamed eladl.jpg" alt="">
              <div class="front-title">
                <span>{{"aboutus.ChiefRiskOfficer-(CRO)" | translate}}<bR>{{"aboutus.CIConsumerFinance" | translate}}</span>
                <h3>{{"aboutus.MohamedElAdl" | translate}}</h3>
              </div>
            </div>
            <div class="back">
              <div class="back-content middle">
                <h2>{{"aboutus.MohamedElAdl" | translate}}</h2>
                <span>{{"aboutus.ChiefRiskOfficer-(CRO)1" | translate}}</span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template swiperSlide>
        <div class="card middle" >
          <div class="front" >
            <img src="/assets/img/aboutus/abdel.png" alt="">
            <div class="front-title">
              <span>{{"aboutus.FinanceDirector" | translate}} <br>{{"aboutus.CIConsumerFinance" | translate}}</span>
              <h3>{{"aboutus.AhmedAbdelRashid" | translate}}</h3>
            </div>
          </div>
          <div class="back">
            <div class="back-content middle">
             <h2>{{"aboutus.AhmedAbdelRashid" | translate}}</h2>
               <span>
                {{"aboutus.FinanceDirector1" | translate}} </span>
            </div>
          </div>
        </div>
        </ng-template>
        <ng-template swiperSlide>
        <div class="card middle" >
          <div class="front" >
            <img src="assets/img/aboutus/maher.png" alt="">
            <div class="front-title">
              <span>{{"aboutus.AuditDirector" | translate}} <br>{{"aboutus.CIConsumerFinance" | translate}}</span>

              <h3>{{"aboutus.MaherElsahaar" | translate}}</h3>
            </div>
          </div>
          <div class="back">
            <div class="back-content middle">
               <h2>{{"aboutus.MaherElsahaar" | translate}}</h2>
               <span>
                {{"aboutus.AuditDirector1" | translate}}</span>
            </div>
          </div>
        </div>
        </ng-template>
    </swiper>
    </div>
  </div>
</div>
