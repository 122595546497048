import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-qrcode',
  templateUrl: './merchant-qrcode.component.html',
  styleUrls: ['./merchant-qrcode.component.scss']
})
export class MerchantQrcodeComponent implements OnInit {
  value:string ='';
  constructor() { }

  ngOnInit(): void {
    this.value = `${localStorage.getItem('token')}`;
  }

}
