import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FilterBycityComponent } from './components/filter-bycity/filter-bycity.component';
import { OurNetworkItemsComponent } from './components/our-network-items/our-network-items.component';
import { OurNetworkPageComponent } from './components/our-network-page/our-network-page.component';
import { SliderOurNetworkComponent } from './components/slider-our-network/slider-our-network.component';

@NgModule({
  declarations: [OurNetworkItemsComponent,FilterBycityComponent,
     OurNetworkPageComponent, SliderOurNetworkComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class OurNetworkModule { }