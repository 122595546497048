import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetPromoSlider } from '../../models/Promo-Slider/get-promo-slider.model';
import { OurNetworkService } from '../../services/portal/our-network.service';

@Component({
  selector: 'app-offer-item',
  templateUrl: './offer-item.component.html',
  styleUrls: ['./offer-item.component.scss']
})
export class OfferItemComponent implements OnInit {
  promos!: GetPromoSlider['Response'];
  offerId:any;
  offers:any;
  constructor(private ourNetworkService: OurNetworkService, @Inject(PLATFORM_ID) private platformId: Object,
  @Inject(DOCUMENT) private document: Document, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.offerId = parseInt(this.route.snapshot.paramMap.get('id'));
      this.getOffers();
    }
  }

 getOffers() {
  this.ourNetworkService.getPromotionsById(this.offerId).subscribe(
    products => {
      this.offers = products['Response'];
    }
  );
}
}
