import { Allcities } from '../../models/Cities/allcities.model';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import AOS from 'aos';
import { OurNetworkService } from 'src/app/core/services/portal/our-network.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  cityItem: Allcities['Response'];
  joinUsForm: FormGroup;
  submitted: boolean = false;
  ERR_Message:boolean = false;
  ERR_Message_Message:any;
  uploadFile: any;
  file: File;
  constructor(private ourNetworkService: OurNetworkService,private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollSmooth();
      document.documentElement.classList.remove("no-scroll");
      AOS.init();
    }

    this.joinUsForm = this.fb.group({
      CommercialName: ['', [Validators.required]],
      fullAddress: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      mobileNo: ['', [Validators.required]],
      fileName: ['', [Validators.required]],
      govName: ['', [Validators.required]],
  });

    this.getgovernaotrate();
  }

  addFile(input: Event): void {
    const file = input.target as HTMLInputElement;
    this.file = file.files[0];
  }
  scrollSmooth(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    }

  getgovernaotrate() {
    this.ourNetworkService.getAllCities().subscribe(cityRes => {
      this.cityItem = cityRes.Response;
    })
  }
  get f() {
    return this.joinUsForm.controls;
  }
  onSubmit() {
    const formData = new FormData();
    this.submitted = true;
    formData.append('CommercialName', this.joinUsForm.value.CommercialName);
    formData.append('fullAddress', this.joinUsForm.value.fullAddress);
    formData.append('fullName', this.joinUsForm.value.fullName);
    formData.append('mobileNo', this.joinUsForm.value.mobileNo);
    formData.append('fileName', this.file);
    formData.append('govName', this.joinUsForm.value.govName);
    this.joinUsForm.invalid;
    if (!this.joinUsForm.valid) {
      return;
    } else {
      this.ourNetworkService.JoinUS(formData).subscribe(res => {
        (res);
      });
    }
  }

}
