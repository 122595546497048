// const baseURL = 'https://dev.souhoola.net';
// const baseUrlNetwork = 'https://dev.souhoola.net';
const baseURL = 'https://api.souhoola.org';
const baseUrlNetwork = 'https://api.souhoola.net';

export const environment = {
  production: true,
  apiLogin: `${baseURL}/api/userLogin`,
  apiLoginCorp: `${baseURL}/api/Corporates/CorpLogin`,
  apiGetCorpData: `${baseURL}/api/Corporates/getCorporateEmployeeList`,
  apiDeleteCorpData: `${baseURL}/api/Corporates/DeleteCorporateEmployee`,
  apiAddCorpData: `${baseURL}/api/Corporates/AddCorporateEmployee`,
  apiUpdateCorpData: `${baseURL}/api/Corporates/UpdateCorporateEmployee`,
  apiUploadCorpData: `${baseURL}/api/Corporates/ImportFromExcel`,
  apiUploadCorpLogo: `${baseURL}/api/Corporates/UploadFiles`,
  GetCorpLogo: `${baseURL}/api/Corporates/GetFiles`,

  apiRegister: `${baseURL}/api/portal/userRegister`,
  getToken: `${baseURL}/api/GetClientData`,
  getMonths: `${baseURL}/Merchant/LM/Months`,

  getPromotions: `${baseURL}/Merchant/LM/ValidPromotions`,
  getInstallment: `${baseURL}/Merchant/LM/Installment`,
  getBookingLoan: `${baseURL}/Merchant/LM/BookingLoan`,
  getBranchStatement: `${baseURL}/Merchant/LM/BranchStatement`,
  refundLoan: `${baseURL}/Merchant/LM/Refund`,


  // apiUrl: `${baseUrlNetwork}/api/Client/LM/Merchants`,
  apiUrl: `${baseUrlNetwork}/api/SearchMerchant`,

  apiCategory: `${baseUrlNetwork}/api/Client/LM/AllCategories`,
  apiPromotions: `${baseUrlNetwork}/api/GetPromotions`,
  apiPromotionsById: `${baseUrlNetwork}/api/GetPromotionsByID`,
  apiCities: `${baseUrlNetwork}/api/Client/LM/AllCities`,
  getClientToken: `${baseURL}/api/portal/getClientData`,
  setPendingTransaction: `${baseURL}/api/portal/setTransactionStatus`,
  showPendingTransaction: `${baseURL}/api/portal/getBranchPendingTransactions`,
  JoinUs: `${baseUrlNetwork}/api/portal/joinOurNetwork`
};
